var render = function render(){
  var _vm$activeFeedback$, _vm$activeFeedback$$f;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "card-img d-flex justify-center",
    staticStyle: {
      "min-height": "350px"
    }
  }, [_vm.fileLoading ? _c('v-col', {
    staticClass: "mt-14",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "image"
    }
  })], 1) : _vm._e(), _c('div', [_vm.currentFileType === '' ? _c('div', {
    staticStyle: {
      "min-height": "350px !important"
    }
  }, [_c('img', {
    staticClass: "max-height-350",
    attrs: {
      "src": "/media/error/no_file.svg",
      "alt": "empty",
      "width": "100%"
    }
  })]) : _c('div', {
    staticClass: "big-v-img",
    staticStyle: {
      "position": "relative"
    },
    on: {
      "click": function click($event) {
        return _vm.resizeImage();
      }
    }
  }, [_c('img', {
    staticClass: "max-height-350",
    staticStyle: {
      "object-fit": "cover",
      "height": "350px"
    },
    attrs: {
      "src": _vm.currentImage,
      "alt": "empty",
      "width": "100%"
    },
    on: {
      "error": _vm.replaceDefaultImage
    }
  }), _c('div', {
    staticClass: "img-background max-height-350"
  }), _vm.currentFileType === 'video/mp4' ? _c('v-icon', {
    staticClass: "cursor-pointer",
    staticStyle: {
      "position": "absolute",
      "top": "40%",
      "left": "30%"
    },
    attrs: {
      "size": "60",
      "color": "white"
    },
    domProps: {
      "textContent": _vm._s('mdi-play-circle-outline')
    }
  }) : _vm._e(), _c('img', {
    staticClass: "img-icon",
    attrs: {
      "src": "/media/feedback/showBig.svg",
      "alt": ""
    }
  })], 1)])], 1), (_vm$activeFeedback$ = _vm.activeFeedback[0]) !== null && _vm$activeFeedback$ !== void 0 && (_vm$activeFeedback$$f = _vm$activeFeedback$.file) !== null && _vm$activeFeedback$$f !== void 0 && _vm$activeFeedback$$f.url ? _c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('button', {
    staticClass: "btn-action",
    on: {
      "click": _vm.prev
    }
  }, [_c('svg', {
    staticStyle: {
      "width": "24px",
      "height": "24px"
    },
    attrs: {
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
    }
  })])]), _c('div', {
    staticClass: "carousel mt-4",
    class: {
      showCenter: _vm.attachmentCenter
    }
  }, [_c('div', {
    ref: "inner",
    staticClass: "inner",
    style: _vm.innerStyles
  }, _vm._l(_vm.images, function (attachment, index) {
    return _c('div', {
      key: index
    }, [attachment.thumb ? _c('div', {
      staticClass: "card",
      class: [_vm.activeImage === index ? 'active' : ''],
      on: {
        "click": function click($event) {
          return _vm.activateImage(index);
        }
      }
    }, [_c('div', {
      on: {
        "click": function click($event) {
          return _vm.$emit('selectFile', index);
        }
      }
    }, [_c('v-avatar', {
      staticClass: "top float-right",
      attrs: {
        "ml-2": "",
        "size": "15",
        "tile": "",
        "color": "white"
      }
    }, [_c('v-icon', {
      staticClass: "",
      attrs: {
        "color": "primary",
        "small": ""
      },
      domProps: {
        "textContent": _vm._s((attachment === null || attachment === void 0 ? void 0 : attachment.mime) === 'video/mp4' ? 'mdi-message-video' : 'mdi-image')
      }
    })], 1), _c('img', {
      staticClass: "pointer",
      staticStyle: {
        "object-fit": "cover",
        "width": "40px",
        "height": "34px",
        "padding-top": "7px"
      },
      attrs: {
        "src": attachment.thumb,
        "alt": "empty",
        "width": "100%"
      },
      on: {
        "error": _vm.replaceDefaultImage
      }
    })], 1)]) : _vm._e()]);
  }), 0)]), _c('button', {
    staticClass: "btn-action",
    on: {
      "click": _vm.next
    }
  }, [_c('svg', {
    staticStyle: {
      "width": "24px",
      "height": "24px"
    },
    attrs: {
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
    }
  })])])]) : _vm._e(), _vm.dialog ? _c('img-modal', {
    attrs: {
      "dialog": _vm.dialog,
      "src": _vm.currentBigImage,
      "type": _vm.currentFileType
    },
    on: {
      "close": function close($event) {
        _vm.dialog = false;
      },
      "openDeleteAttachmentModal": function openDeleteAttachmentModal($event) {
        _vm.deleteAttachmentModal = true;
      }
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }