<template>
  <v-card :outlined="$vuetify.theme.dark" flat class="pa-2">
    <div class="mb-5 mx-2 bold-text subListFont">
      Attachment
      <span class="float-right top">
        <action-dropdown
          id="feedbackAction"
          v-if="
            activeFeedback?.status !== 3 &&
            canManageApp &&
            attachmentList.length
          "
        >
          <template #list>
            <v-list-item @click="openDeleteVideo(defaultImage?.id)">
              <v-icon small left class="info-color">delete</v-icon>
              <div class="regular-font font-14 info-color">Delete</div>
            </v-list-item>
          </template>
        </action-dropdown>
      </span>
    </div>
    <v-row v-if="loading">
      <v-col cols="4">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="8">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else-if="!attachmentList.length">
      <v-col cols="12">
        <div class="text-center">
          <img
            class=""
            src="/media/error/no_file.svg"
            alt="empty"
            height="200"
            width="100%"
          />
        </div>
        <div class="text-center subListFont py-4">No attachments</div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="4">
        <v-card flat>
          <v-overlay :value="true" absolute v-if="getFeedbackLoader">
            <v-progress-circular width="8" size="100" indeterminate>
              {{ 100 > getTotalProgress ? getTotalProgress : "Uploading" }}
            </v-progress-circular>
          </v-overlay>
          <div
            v-if="!getDataFromFeedbackSocket.length && !attachmentList.length"
            style="min-height: 350px !important"
          >
            <img
              class="max-height-350"
              src="/media/error/no_file.svg"
              alt="empty"
              width="100%"
            />
          </div>
          <carousel
            v-else
            :activeFeedback="getActiveAttachmentList"
            :src="src"
            :dialog="dialog"
            :selectedIndex="selectedIndex"
            :from-page="fromPage"
            @selectFile="selectFile"
            @setSelectedIndex="selectFile"
            @attachmentExtension="attachmentExtension"
          ></carousel>
        </v-card>

        <v-card
          v-if="
            expansion.includes(4) &&
            (!getTeamPlanUsage.feedback.sessions || !defaultImage?.session?.id)
          "
          class="mt-15 pa-2"
        >
          <v-row>
            <v-col class="text-center" cols="12">
              <div
                v-if="
                  !defaultImage?.session?.id &&
                  getTeamPlanUsage.feedback.sessions
                "
              >
                Use TestApp.io SDK to collect valuable information.
              </div>
              <div v-else>
                <div>
                  <v-avatar height="80" width="80">
                    <v-icon color="" v-text="'mdi-lock'"></v-icon>
                  </v-avatar>
                </div>
                <div class="text-center">
                  Unlock valuable insights and improve your app's performance
                  with TestApp.io's advanced feedback session data.
                  <br /><br />Upgrade today to take your mobile app testing to
                  the next level.
                </div>
              </div>
            </v-col>
            <v-col class="text-center">
              <v-btn
                target="_blank"
                v-if="
                  !defaultImage?.session?.id &&
                  getTeamPlanUsage.feedback.sessions
                "
                href="https://help.testapp.io/about-sdk/"
                text
                class="text-transform-none"
                color="primary"
              >
                Learn more...
              </v-btn>
              <v-btn
                v-else
                @click="openStartTrialPopup = true"
                class="text-transform-none"
                color="primary"
              >
                <v-icon v-text="'mdi-lock'" left></v-icon> Unlock sessions
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-row :class="{ 'mx-2': $vuetify.breakpoint.mdAndUp }">
          <v-col cols="12" class="pb-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-expansion-panels flat multiple v-model="expansion">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="bold-text darkGrey--text">File</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="fileDetailHeader"
                :items="[defaultImage?.file]"
                mobile-break-point="0"
                class="v-card v-sheet v-sheet--outlined hide-v-data-footer__select"
                hide-default-footer
              >
                <template #item.name="{ item }">
                  <div v-if="item?.name">
                    <div v-if="item?.name?.length > 20">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class v-on="on">
                            {{ item?.name?.substring(0, 20) + "..." }}
                          </span>
                        </template>
                        <span>
                          {{ item?.name }}
                        </span>
                      </v-tooltip>
                    </div>
                    <div v-else>
                      {{ item?.name }}
                    </div>
                  </div>
                  <div v-else>-</div>
                </template>
                <template #item.size="{ item }">
                  <div>{{ formatBytes(item?.size) }}</div>
                </template>
                <template #item.height="{ item }">
                  <div>{{ item?.height ? item?.height + "px" : "-" }}</div>
                </template>
                <template #item.width="{ item }">
                  <div>{{ item?.width ? item?.width + "px" : "-" }}</div>
                </template>
                <template #item.duration="{ item }">
                  {{ getDuration(item) }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="bold-text darkGrey--text">App</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <no-session-data
                v-if="['Portal', 'App'].includes(defaultImage?.platform?.name)"
              ></no-session-data>
              <v-data-table
                v-else
                :headers="appDetailHeader"
                :items="getAppInformation"
                class="v-card v-sheet v-sheet--outlined hide-v-data-footer__select"
                hide-default-footer
              >
                <template #item.name="{ item }">
                  <div>{{ item?.name || "-" }}</div>
                </template>
                <template #item.package="{ item }">
                  <div>{{ item?.package || "-" }}</div>
                </template>
                <template #item.version="{ item }">
                  <div>{{ item?.version || "-" }}</div>
                </template>
                <template #item.version_code="{ item }">
                  <div>{{ item?.version_code || "-" }}</div>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="bold-text darkGrey--text">Device</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <no-session-data
                v-if="defaultImage?.platform?.name === 'Portal'"
              ></no-session-data>
              <v-data-table
                v-else
                :headers="deviceDetailHeader"
                :items="getDeviceInformation"
                class="v-card v-sheet v-sheet--outlined hide-v-data-footer__select"
                hide-default-footer
              >
                <template #item.manufacturer="{ item }">
                  <div>{{ item?.manufacturer || "-" }}</div>
                </template>
                <template #item.model="{ item }">
                  <div v-if="item?.manufacturer == 'Apple'">
                    {{ appDeviceList[item?.model] }}
                  </div>
                  <div v-else>
                    {{ item.model }}
                  </div>
                </template>
                <template #item.os="{ item }">
                  <div>
                    {{ item?.os || "-" }}
                  </div>
                </template>
                <template #item.os_version="{ item }">
                  <div>{{ item?.os_version }}</div>
                </template>
                <template #item.ram="{ item }">
                  <div>
                    {{ item.type }}
                  </div>
                </template>
                <template #item.locale="{ item }">
                  {{ item?.locale || "-" }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="bold-text darkGrey--text">Platform</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="platformDetailHeader"
                :items="[defaultImage?.platform]"
                class="v-card v-sheet v-sheet--outlined hide-v-data-footer__select"
                hide-default-footer
              >
                <template #item.name="{ item }">
                  <div>
                    {{ item.name }}
                  </div>
                </template>
                <template #item.size="{ item }">
                  <div>{{ item?.version || "-" }}</div>
                </template>
                <template #item.height="{ item }">
                  <div>{{ item?.version_code || "-" }}</div>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="bold-text darkGrey--text">Session</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <session-display-chart
                  :key="defaultImage?.id"
                  :active-feedback="defaultImage"
                ></session-display-chart>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="defaultImage?.id">
            <v-expansion-panel-header>
              <div class="bold-text darkGrey--text">Internal Notes</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-if="defaultImage?.id" class="mx-2">
                <v-col cols="12" class="regular-font font-12">
                  <div v-if="!openAddNoteModal">
                    <div v-if="defaultImage?.note">
                      <span class="float-right top">
                        <v-icon
                          @click="editAttachmentNote(defaultImage)"
                          v-text="'mdi-pencil'"
                        ></v-icon>
                      </span>
                      <vue-readmore
                        :text="defaultImage?.note"
                        :length="100"
                      ></vue-readmore>
                    </div>

                    <div v-else>
                      <span v-if="canManageFeedback">
                        <span
                          @click="editAttachmentNote(defaultImage)"
                          class="primary--text cursor-pointer"
                          >Click here</span
                        >
                        to update note
                      </span>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <v-row v-if="openAddNoteModal">
                    <v-col cols="12">
                      <v-textarea
                        v-model="attachmentNote"
                        rows="2"
                        auto-grow
                        filled
                        row-height="2"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn
                        :disabled="busy"
                        @click="openAddNoteModal = false"
                        class="mr-3 text-transform-none"
                        color="primary"
                        text
                        depressed
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        :loading="busy"
                        @click="submitAttachmentNote"
                        class="mr-3 text-transform-none"
                        color="primary"
                        :outlined="$vuetify.theme.dark"
                        depressed
                      >
                        Update
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <Modal
      v-model="deleteAttachmentModal"
      @close="deleteAttachmentModal = false"
    >
      <template #card>
        <v-card>
          <v-card-title class="font-weight-bold"
            >Delete attachment</v-card-title
          >
          <v-divider />
          <v-card-text class="my-4 message">
            <p>Are you sure you want to delete this attachment?</p>
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-3">
            <v-spacer />
            <v-btn
              id="leaveAppCancelBtn"
              color="primary"
              text
              :disabled="busy"
              class="text-transform-none"
              @click="deleteAttachmentModal = false"
              >No</v-btn
            >
            <v-btn
              class="text-transform-none"
              color="primary"
              :loading="busy"
              @click="onConfirmDeleteAttachment"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openSubscriptionModal = false"
      v-model="openSubscriptionModal"
    >
      <template #message>
        <plan-pricing
          from-modal
          from-upgrade-component
          :upgrade-plan-text="'Upgrade your plan'"
          @success="$emit('close'), (openSubscriptionModal = false)"
          @close="openSubscriptionModal = false"
        ></plan-pricing>
      </template>
    </Modal>
    <Modal
      v-model="openStartTrialPopup"
      no-py
      @close="openStartTrialPopup = false"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 900 : ''"
    >
      <template #message>
        <start-trial-modal
          v-if="openStartTrialPopup"
          trigger-from="feedbackSessions"
          @open-subscribe="openSubscriptionModal = true"
          @close="openStartTrialPopup = false"
        ></start-trial-modal>
      </template>
    </Modal>
  </v-card>
</template>

<script>
import Carousel from "@/view/components/Feedback/Carousel";
import { formatBytes } from "@/core/services/helper.service";
// import VueReadmore from "@/view/components/Common/VueReadmore";
import { mapGetters, mapMutations } from "vuex";
import veeValidate from "@/mixins/veeValidate";
import {
  DELETE_FEEDBACK_ATTACHMENT,
  GET_FEEDBACK_ATTACHMENT,
  UPDATE_FEEDBACK_ATTACHMENT,
} from "@/store/releases/releases.module";
import VueReadmore from "@/view/components/Common/VueReadmore";
import { appDeviceList } from "@/consts/appDeviceConst.js";
import SessionDisplayChart from "@/view/components/Feedback/SessionDisplayChart";
import PlanPricing from "@/view/components/Team/PlanPricing";
import NoSessionData from "@/view/components/Feedback/NoSessionData";
import StartTrialModal from "@/view/components/Common/StartTrialModal";

export default {
  name: "Attachment",
  components: {
    StartTrialModal,
    NoSessionData,
    PlanPricing,
    SessionDisplayChart,
    VueReadmore,
    Carousel,
  },
  mixins: [veeValidate],
  data() {
    return {
      formatBytes,
      more: false,
      selectedId: "",
      textMaxLength: 50,
      openStartTrialPopup: false,
      expansion: [0],
      appDeviceList,
      openSubscriptionModal: false,
      openSubscribeModal: false,
      fileDetailHeader: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          width: "200",
          value: "name",
        },
        {
          text: "Size",
          align: "left",
          sortable: false,
          width: "100",
          value: "size",
        },
        {
          text: "Height",
          align: "left",
          sortable: false,
          width: "100",
          value: "height",
        },
        {
          text: "Width",
          align: "left",
          sortable: false,
          width: "100",
          value: "width",
        },
        {
          text: "Duration",
          align: "left",
          sortable: false,
          width: "100",
          value: "duration",
        },
      ],
      appDetailHeader: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          width: "150",
          value: "name",
        },
        {
          text: "Package",
          align: "left",
          sortable: false,
          width: "150",
          value: "package",
        },
        {
          text: "Version",
          align: "left",
          sortable: false,
          width: "150",
          value: "version",
        },
        {
          text: "Version Code",
          align: "left",
          sortable: false,
          width: "150",
          value: "version_code",
        },
      ],
      platformDetailHeader: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          width: "100",
          value: "name",
        },
        {
          text: "Version",
          align: "left",
          sortable: false,
          width: "100",
          value: "version",
        },
        {
          text: "Version Code",
          align: "left",
          sortable: false,
          width: "100",
          value: "version_code",
        },
      ],
      deviceDetailHeader: [
        {
          text: "Manufacturer",
          align: "left",
          sortable: false,
          width: "50",
          value: "manufacturer",
        },
        {
          text: "Model",
          align: "left",
          sortable: false,
          width: "100",
          value: "model",
        },
        {
          text: "OS",
          align: "left",
          sortable: false,
          width: "50",
          value: "os",
        },
        {
          text: "OS Version",
          align: "left",
          sortable: false,
          width: "150",
          value: "os_version",
        },
        {
          text: "Type",
          align: "left",
          sortable: false,
          width: "100",
          value: "type",
        },
        // {
        //   text: "Push Notification",
        //   align: "left",
        //   sortable: false,
        //   width: "100",
        //   value: "type",
        // },
        {
          text: "Locale",
          align: "left",
          sortable: false,
          width: "60",
          value: "locale",
        },
      ],
      isShowInfo: true,
      loading: false,
      isShowAdditionalInfo: true,
      isBlur: true,
      attachmentNote: "",
      defaultImage: {},
      busy: false,
      attachmentList: [],
      openAddNoteModal: false,
      deleteAttachmentModal: false,
      extensionType: false,
    };
  },

  props: {
    fileList: {
      type: Array,
      default() {
        return [];
      },
    },
    fromPage: {
      type: Boolean,
      default: false,
    },
    selectedIndex: {
      type: Number,
    },
    // defaultImage: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
    openDeleteAttachment: {
      type: Boolean,
      default: false,
    },
    activeFeedback: {
      type: Object,
    },
    pages: {
      type: Number,
    },
    page: {
      type: Number,
    },
    src: {
      type: String,
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    attachmentStatus: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    activeFeedback() {
      this.fetchAttachments();
      this.setActiveFeedbackId();
    },
    defaultImage(val) {
      if (["image/jpeg", "image/png", "image/jpg"].includes(val?.mime)) {
        this.fileDetailHeader.pop();
      }
    },
    getDataFromFeedbackSocket(val) {
      let attachments = [];
      if (val && val.length) {
        attachments = val?.map((item) => {
          if (item.status === 4 && item.feedback_id === this.defaultImage?.id) {
            return {
              id: item.id,
              status: item.status,
              file: {
                height: item?.file?.height,
                width: item?.file?.width,
                name: item?.file?.name,
                duration: item?.file?.duration,
                size: item?.file?.size,
                note: item?.file?.note,
                url: this.getAttachmentThumbnailList.find(
                  (x) => x.id === item?.file?.id
                )?.url,
                thumbnail: this.getAttachmentThumbnailList.find(
                  (x) => x.id === item?.file?.id
                )?.thumb,
              },
            };
          } else if (val.feedback_id === this.defaultImage?.id) {
            this.clearAttachmentList();
            return {
              id: item.id,
              status: item.status,
              file: {
                height: item?.file?.height,
                width: item?.file?.width,
                name: item?.file?.name,
                duration: item?.file?.duration,
                size: item?.file?.size,
                note: item?.file?.note,
                url: item.url,
                thumbnail: this.getAttachmentThumbnailList.find(
                  (x) => x.id === item?.file?.id
                )?.thumb,
              },
            };
          }
        });
        this.setFeedbackLoader(false);
        this.attachmentList = [...attachments];
        if (this.$route.params.attachment_id && !this.fromPage) {
          this.defaultImage = this.attachmentList.find(
            (x) => x.id === this.$route.params.attachment_id
          );
        } else {
          this.defaultImage = this.attachmentList[0];
        }
      }
    },
  },
  mounted() {
    this.buttonText();
    this.fetchAttachments();
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      getDataFromFeedbackSocket: "getDataFromFeedbackSocket",
      getFeedbackLoader: "getFeedbackLoader",
      getTotalProgress: "getTotalProgress",
      getTeamPlanUsage: "getTeamPlanUsage",
      getAttachmentThumbnailList: "getAttachmentThumbnailList",
    }),
    getDeviceInformation() {
      if (this.defaultImage?.platform?.name === "SDK") {
        return [this.defaultImage?.session?.info?.device];
      } else {
        return [this.defaultImage?.device];
      }
    },
    getAppInformation() {
      if (this.defaultImage?.platform?.name === "SDK") {
        return [this.defaultImage?.session?.info?.app];
      } else {
        return [this.defaultImage?.app];
      }
    },
    getActiveAttachmentList() {
      return this.attachmentList.length ? this.attachmentList : [];
    },
  },

  methods: {
    ...mapMutations({
      clearAttachmentList: "clearAttachmentList",
      setFeedbackLoader: "setFeedbackLoader",
      setActiveFeedbackId: "setActiveFeedbackId",
    }),
    attachmentExtension(extension) {
      this.extensionType = extension;
    },
    getDuration(detail) {
      let seconds = detail?.duration;
      let min = (((seconds % 31536000) % 86400) % 3600) / 60;
      let sec = (((seconds % 31536000) % 86400) % 3600) % 60;
      if (detail?.duration) {
        return `${Math.floor(min)}m:${Math.floor(sec)}s`;
      } else {
        return "-";
      }
    },
    editAttachmentNote(detail) {
      this.attachmentId = detail?.id;
      this.attachmentNote = detail?.note;
      this.openAddNoteModal = true;
    },
    submitAttachmentNote() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(UPDATE_FEEDBACK_ATTACHMENT, {
          app_id: this.$route.params.app_id,
          feedback_id: this.activeFeedback?.id,
          id: this.attachmentId,
          note: this.attachmentNote,
        })
        .then((response) => {
          this.notifyUserMessage(response.message);
          this.defaultImage.note = this.attachmentNote;
          this.openAddNoteModal = false;
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    fetchAttachments(fromDelete) {
      this.attachmentList = [];
      if (this.activeFeedback?.id) {
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(GET_FEEDBACK_ATTACHMENT, {
            app_id: this.$route?.params?.app_id || "",
            feedback_id: this.activeFeedback?.id,
          })
          .then((response) => {
            if (this.$route.params.attachment_id && !this.fromPage) {
              this.defaultImage = response?.data?.find(
                (x) => x.id === this.$route?.params?.attachment_id
              );
            } else {
              this.defaultImage = response.data[0];
            }
            if (fromDelete) {
              this.attachmentList = response.data;
            } else {
              this.attachmentList = [...response.data];
            }
            this.$nextTick(() => {
              if (
                !this.attachmentList.length &&
                this.getAttachmentThumbnailList.length
              ) {
                this.defaultImage = this.getAttachmentThumbnailList[0];
                this.attachmentList = this.getAttachmentThumbnailList.map(
                  (item) => {
                    return {
                      id: item.id,
                      file: {
                        name: item.name,
                        size: item.size,
                        url: item.url,
                        mime: item.mime,
                        thumbnail: item.thumb,
                      },
                    };
                  }
                );
              }
            });
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.isBlur = scrollTop + clientHeight < scrollHeight - 120;
      if (scrollTop < 40) {
        this.isBlur = true;
      }
    },

    toggleInfo(e) {
      this.isShowInfo = !this.isShowInfo;
      this.more = !this.more;

      e.target.classList.toggle("active");
    },

    buttonText(text) {
      if (text?.length > this.textMaxLength) {
        return this.more ? "Hide" : "More";
      } else {
        return "";
      }
    },

    toggleText() {
      this.more = !this.more;
    },

    selectFile(index) {
      this.defaultImage = this.attachmentList[index];
      this.$router.push({
        name: "app-feedback-attachment-detail",
        params: {
          app_id: this.$route.params.app_id,
          feedback_id: this.$route.params.feedback_id,
          attachment_id: this.defaultImage.id,
        },
      });
    },
    onConfirmDeleteAttachment() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DELETE_FEEDBACK_ATTACHMENT, {
          app_id: this.$route.params.app_id,
          feedback_id: this.activeFeedback?.id,
          attachment_id: this.selectedId,
        })
        .then((response) => {
          this.busy = false;
          this.notifyUserMessage({ message: response.message });
          this.selectedImageId = null;
          this.deleteAttachmentModal = false;
          this.fetchAttachments("fromDelete");
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openDeleteVideo(id) {
      this.selectedId = id;
      this.deleteAttachmentModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.card-radius {
  border-radius: 20px;
}
.v-expansion-panel-content__wrap {
  padding-right: 0px !important;
}

.title-style {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.009em;
}

.info-title {
  color: #8a8e9d;
}

.select-option {
  max-width: 198px;
  border-radius: 4px;
  flex: none;
  order: 1;
}

.feedback-search {
  background: #f1f5fc;
  border-radius: 10px;

  ::v-deep fieldset {
    border: none;
  }
}

.bug-btn {
  border: 1px solid #adbad7;
  border-radius: 4px;
}

.testapp-tabs {
  ::v-deep .v-tabs-bar__content {
    border-bottom: none;
  }
}

.bottom-side {
  position: fixed;
  bottom: 0;
  height: 80px;
  background-color: #ffffff;
  width: 100%;
  align-items: center;
  padding: 0 46px 0 13px;
}

.list-scroll {
  margin-bottom: 130px;
}

.box-shadow {
  box-shadow: 0 100px 80px rgba(142, 142, 141, 0.05),
    0 41.7776px 33.4221px rgba(142, 142, 141, 0.0359427),
    0 22.3363px 17.869px rgba(142, 142, 141, 0.0298054),
    0 12.5216px 10.0172px rgba(142, 142, 141, 0.025),
    0 6.6501px 5.32008px rgba(142, 142, 141, 0.0201946),
    0 2.76726px 2.21381px rgba(142, 142, 141, 0.0140573) !important;
}

.bug-card {
  border-radius: 10px !important;
}

.text-body1 {
  max-height: 300px;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #396bdb;
  border-radius: 10px;
}

.blur-info {
  left: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 17.58%,
    #ffffff 67.82%
  );
  height: 120px;
  width: 100%;
  border-radius: 20px;
}

.arrow {
  position: absolute;
  top: unset !important;
  right: 60px;
}

.toggle.active .arrow::before {
  transform: rotate(-45deg);
}

.toggle.active .arrow::after {
  transform: rotate(45deg);
}
.empty-attachment {
  width: 100%;
  height: 350px;
  background-color: white;
  color: #2d2d2d;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
