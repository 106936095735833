<template>
  <v-dialog scrollable v-model="dialog" persistent max-width="60%">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon v-text="'mdi-close'"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="scr">
          <video
            v-if="type === 'video/mp4'"
            width="100%"
            controls
            class="pointer"
            height="350"
          >
            <source
              style="height: 350px"
              :src="src"
              type="video/mp4"
              class="max-height-350"
            />
          </video>
          <img
            v-else
            style="max-height: 700px; object-fit: contain"
            class="my-3 contain"
            :src="src"
            alt="empty"
            @error="replaceDefaultImage"
            width="100%"
          />
        </v-container>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },

  methods: {
    replaceDefaultImage(e) {
      e.target.src = "/media/error/no_file.svg";
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
