var render = function render(){
  var _vm$activeFeedback, _vm$activeFeedback2, _vm$activeFeedback3, _vm$activeFeedback4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('div', {
    staticClass: "subListFont"
  }, [_vm._v("Feedback Detail")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "pa-2 bold-text subListFont"
  }, [_vm._v("Session")]), _c('v-data-table', {
    staticClass: "v-card v-sheet v-sheet--outlined hide-v-data-footer__select radius-10 release-table",
    attrs: {
      "expanded": _vm.latestExpand,
      "headers": _vm.sessionHeaders,
      "items": _vm.feedbackSessions,
      "mobile-breakpoint": "0",
      "hide-default-footer": ""
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.latestExpand = $event;
      },
      "click:row": _vm.expendLatestRow
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.location",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('country-city', {
          attrs: {
            "detail": item.location
          }
        })];
      }
    }, {
      key: "item.stats",
      fn: function fn(_ref3) {
        var _item$stats, _item$stats2, _item$stats3, _item$stats4, _item$stats5;
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_c('span', _vm._g({}, on), [_vm._v(" View ")])];
            }
          }], null, true)
        }, [_c('div', [_c('div', [_vm._v("Errors: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.errors))]), _c('div', [_vm._v("Events: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.events))]), _c('div', [_vm._v("Screens: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : _item$stats3.screens))]), _c('div', [_vm._v("Performance: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : _item$stats4.performance))]), _c('div', [_vm._v(" User Attributes: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats5 = item.stats) === null || _item$stats5 === void 0 ? void 0 : _item$stats5.user_attributes) + " ")])])])];
      }
    }, {
      key: "item.time",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": _vm.dayjs(item.start_at).unix()
          }
        })];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "primary--text cursor-pointer",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'sessions-detail',
                params: {
                  app_id: _vm.$route.params.app_id,
                  session_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" Detail "), _c('v-icon', {
          attrs: {
            "right": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-chevron-right')
          }
        })], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref7) {
        var _item$context, _item$context$sdk, _item$context2, _item$context3;
        var headers = _ref7.headers,
          item = _ref7.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-card', {
          staticClass: "pa-4",
          attrs: {
            "flat": "",
            "tile": ""
          }
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('span', [_vm._v(" Source: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context = item.context) === null || _item$context === void 0 ? void 0 : (_item$context$sdk = _item$context.sdk) === null || _item$context$sdk === void 0 ? void 0 : _item$context$sdk.name) + " SDK ")]), _c('v-col', {
          staticClass: "py-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('span', [_vm._v(" User agent: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context2 = item.context) === null || _item$context2 === void 0 ? void 0 : _item$context2.user_agent) + " ")]), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('span', [_vm._v(" IP: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context3 = item.context) === null || _item$context3 === void 0 ? void 0 : _item$context3.ip) + " ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-card', {
    staticClass: "pa-2 py-4",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "lightBackground py-3 mx-2",
    attrs: {
      "cols": "9",
      "md": "12"
    }
  }, [_c('span', {
    staticClass: "float-right top"
  }, [(_vm$activeFeedback = _vm.activeFeedback) !== null && _vm$activeFeedback !== void 0 && _vm$activeFeedback.id && ((_vm$activeFeedback2 = _vm.activeFeedback) === null || _vm$activeFeedback2 === void 0 ? void 0 : _vm$activeFeedback2.status) !== 3 && _vm.canManageApp ? _c('action-dropdown', {
    attrs: {
      "id": "feedbackAction"
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('v-list-item', {
          attrs: {
            "disabled": _vm.busy
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")]), _c('div', {
          staticClass: "regular-font font-14",
          on: {
            "click": function click($event) {
              return _vm.$emit('feedback-update-dialog');
            }
          }
        }, [_vm._v(" Edit Message ")])], 1)];
      },
      proxy: true
    }], null, false, 1304154649)
  }) : _vm._e()], 1), _c('span', [!((_vm$activeFeedback3 = _vm.activeFeedback) !== null && _vm$activeFeedback3 !== void 0 && _vm$activeFeedback3.message) ? _c('span', [_vm._v(" No message ")]) : _c('vue-readmore', {
    attrs: {
      "length": 100,
      "text": (_vm$activeFeedback4 = _vm.activeFeedback) === null || _vm$activeFeedback4 === void 0 ? void 0 : _vm$activeFeedback4.message
    }
  })], 1)])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_vm.attachmentLoading || _vm.loading ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card"
    }
  })], 1) : _vm._e(), !_vm.loading || !_vm.attachmentLoading ? _c('attachment-carosel', {
    attrs: {
      "activeFeedback": _vm.activeFeedback,
      "fileList": _vm.releaseInstallFeedback,
      "page": _vm.page,
      "selectedIndex": _vm.selectedIndex
    },
    on: {
      "onConfirmDeleteAttachment": function onConfirmDeleteAttachment($event) {
        return _vm.$emit('confirm-delete-attachment');
      },
      "update": function update($event) {
        return _vm.$emit('update-attachment');
      }
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_vm.loading ? _c('div') : _c('information-notes', {
    attrs: {
      "activeFeedback": _vm.activeFeedback
    },
    on: {
      "openDeleteDialog": function openDeleteDialog($event) {
        return _vm.$emit('delete-dialog', _vm.activeFeedback.id);
      },
      "openFlagging": _vm.openFlagging,
      "openUpdateFeedback": function openUpdateFeedback($event) {
        return _vm.$emit('update-feedback', _vm.activeFeedback);
      },
      "updateStatus": _vm.updateStatus
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }