<!-- eslint-disable-next-line vue/valid-v-slot -->
<template>
  <FeedbackDataTable title="Feedback" />
</template>

<script>
import FeedbackDataTable from "@/view/components/Feedback/FeedbackDataTable.vue";

export default {
  components: {
    FeedbackDataTable,
  },
};
</script>