<template #list>
    <div>
        <v-list-item v-if="[1, 2].includes(activeFeedback.status) && canManageApp" @click="$emit('openFlagging', activeFeedback.id, 3)">
            <v-icon v-text="'mdi-flag-outline'" small></v-icon>
            Flag as Inappropriate
        </v-list-item>
        <v-list-item id="markAsResoved" v-if="[1].includes(activeFeedback.status) && canManageApp" @click="$emit('updateStatus', activeFeedback.id, 2)">
            <v-icon small>mdi-check</v-icon>
            Mark as Resolved
        </v-list-item>
        <v-list-item v-if="[2].includes(activeFeedback.status) && canManageApp" @click="$emit('updateStatus', activeFeedback.id, 1)">
            <v-icon small>mdi-check</v-icon>
            Mark as Not Resolved
        </v-list-item>
        <v-list-item v-if="activeFeedback.user.id === currentUser.id" @click="$emit('openUpdateFeedback', activeFeedback)">
            <v-icon small>edit</v-icon>
            update
        </v-list-item>
        <v-list-item @click="$emit('openDeleteDialog', activeFeedback.id)">
            <v-icon small left>delete</v-icon>
            Delete
        </v-list-item>
    </div>
</template>

<script>
export default {
    name: "FeedbackDropdownItem",

    props: {
        activeFeedback: {
            type: Object,
        },
        currentUser: {
            type: Object,
        },
    },
};
</script>
