<template>
  <!--  <v-card class="pa-1">-->
  <v-row>
    <div
      v-if="!getTeamPlanUsage.feedback.sessions || !activeFeedback.session.id"
      class="watermark"
    >
      Demo Data
    </div>
    <div
      v-if="!getTeamPlanUsage.feedback.sessions || !activeFeedback.session.id"
      class="secondwatermark"
    >
      Demo Data
    </div>
    <v-col cols="12">
      <v-row align="center">
        <v-col cols="8">
          <div
            v-if="
              !getTeamPlanUsage.feedback.sessions || !activeFeedback.session.id
            "
          >
            2m:33s
          </div>
          <div v-else>
            {{ getSessionDuration() }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col class="pb-0" cols="12">
          <div class="subListFont">
            CPU
            <v-chip
              v-if="
                !getTeamPlanUsage.feedback.sessions ||
                !activeFeedback.session.id
              "
              label
              x-small
            >
              Demo
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12">
          <apexchart
            :height="100"
            :options="apexOptions"
            :series="cpuChartData"
            type="line"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row align="center">
        <v-col class="pb-0" cols="12">
          <div class="subListFont">
            RAM
            <v-chip
              v-if="
                !getTeamPlanUsage.feedback.sessions ||
                !activeFeedback.session.id
              "
              label
              x-small
            >
              Demo
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12">
          <!--           <line-charts :chart-data="downloadInstallChartData"></line-charts>-->
          <apexchart
            :height="100"
            :options="ramChartOptions"
            :series="ramChartData"
            type="line"
          />
          <!--                          <v-sparkline type="bar" height="75" line-width="1" :value="ramChartData?.datasets[0]?.data"></v-sparkline>-->
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row align="center">
        <v-col class="pb-0" cols="12">
          <div class="subListFont">
            Stats
            <v-chip
              v-if="
                !getTeamPlanUsage.feedback.sessions ||
                !activeFeedback.session.id
              "
              label
              x-small
            >
              Demo
            </v-chip>
          </div>
        </v-col>
        <v-col class="px-0 pb-0" cols="12">
          <apexchart
            :options="networkOptions"
            :series="networkSeries"
            height="100"
            type="rangeBar"
          ></apexchart>
        </v-col>
        <v-col class="px-0 pt-0" cols="12">
          <apexchart
            :options="chartOptions"
            :series="batterySeries"
            height="100"
            type="rangeBar"
          ></apexchart>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row align="center">
        <v-col cols="12">
          <div class="subListFont">
            Events
            <v-chip
              v-if="
                !getTeamPlanUsage.feedback.sessions ||
                !activeFeedback.session.id
              "
              label
              x-small
            >
              Demo
            </v-chip>
          </div>
          <apexchart
            :options="eventOptions"
            :series="eventsChartData"
            height="75"
            type="scatter"
            width="100%"
            @markerClick="openDetailEventCard"
          ></apexchart>
          <div class="subListFont">
            Errors
            <v-chip
              v-if="
                !getTeamPlanUsage.feedback.sessions ||
                !activeFeedback.session.id
              "
              label
              x-small
            >
              Demo
            </v-chip>
          </div>
          <apexchart
            :options="errorOptions"
            :series="errorChartData"
            height="75"
            type="scatter"
            width="100%"
            @markerClick="openDetailErrorCard"
          ></apexchart>
        </v-col>
        <template v-if="selectedEventDetail?.name">
          <v-col cols="12" md="4">
            <v-card class="pa-2" outlined>
              <v-row>
                <v-col>
                  <div class="pb-0">
                    {{ selectedEventDetail.name }}
                  </div>
                  <div class="pb-2">
                    <v-chip
                      :color="
                        selectedEventDetail.type === 'event'
                          ? 'primary'
                          : 'error'
                      "
                      label
                      outlined
                      x-small
                    >
                      {{ selectedEventDetail.type }}
                    </v-chip>
                  </div>
                  <div
                    v-for="(parameter, pindex) in Object.keys(
                      selectedEventDetail.parameters
                    )"
                    :key="pindex"
                  >
                    <span>{{ parameter }}: </span>
                    <span
                      >{{ selectedEventDetail.parameters[parameter] || "0" }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <v-col cols="12"></v-col>
  </v-row>
  <!--  </v-card>-->
</template>

<script>
import { formatBytes } from "@/core/services/helper.service";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    activeFeedback: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dayjs,
      formatBytes,
      duration: "5 min",
      apexOptions: {
        chart: {
          id: "cpu",
          group: "session",
          toolbar: {
            show: true,
          },
          type: "line",
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: [
            "08:03:44AM GMT",
            "08:03:45AM GMT",
            "08:03:46AM GMT",
            "08:03:47AM GMT",
            "08:03:48AM GMT",
            "08:03:49AM GMT",
          ],
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            // let labels =
            let labels = w.globals.categoryLabels[dataPointIndex];
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${labels}
                  </v-card-title>
                  <v-divider></v-divider>
                  <div>
                  CPU: ${data}%
                  </div>
                 </v-card>`;
          },
        },
      },
      ramChartOptions: {
        chart: {
          id: "ram-chart",
          group: "session",
          toolbar: {
            show: true,
          },
          type: "line",
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: [
            "08:03:44AM GMT",
            "08:03:45AM GMT",
            "08:03:46AM GMT",
            "08:03:47AM GMT",
            "08:03:48AM GMT",
            "08:03:49AM GMT",
          ],
          show: false,
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            // let labels =
            let labels = w.globals.categoryLabels[dataPointIndex];
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            // const start = dayjs(data.x * 1000).format= ('hh:mm:ss a')
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${labels}
                  </v-card-title>
                  <v-divider></v-divider>
                  <div>
                  RAM: ${data}MB
                  </div>
                 </v-card>`;
          },
        },
      },
      eventOptions: {
        chart: {
          id: "event-chart",
          group: "",
          toolbar: {
            show: true,
          },
          type: "scatter",
          zoom: {
            enabled: true,
          },
        },
        legend: {
          position: "bottom",
          show: false,
        },
        colors: ["#008FFB"],
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let eventClass = {
              error: "error",
              event: "primary",
            };
            const start = dayjs(data.x * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                  <ul>
                  <li>${data.info}: ${start} ${data.timezone}</li>
                  <li>Type: <span class="${
                    eventClass[data.type]
                  }--text text-capitalize">${data.type}</span></li>
                  </ul>
                 </v-card>`;
          },
        },
        xaxis: {
          title: "Events",
          categories: [],
          // type: 'category',
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
      errorOptions: {
        chart: {
          id: "error-chart",
          group: "",
          toolbar: {
            show: true,
          },
          type: "scatter",
          zoom: {
            enabled: true,
          },
        },
        legend: {
          position: "bottom",
          show: false,
        },
        colors: ["#D7263D"],
        tooltip: {
          custom: function ({ seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let eventClass = {
              error: "error",
              event: "primary",
            };
            const start = dayjs(data.x * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                  <ul>
                  <li>${data.info}: ${start} ${data.timezone}</li>
                  <li>Type: <span class="${
                    eventClass[data.type]
                  }--text text-capitalize">${data.type}</span></li>
                  </ul>
                 </v-card>`;
          },
        },
        xaxis: {
          title: "Events",
          categories: [],
          // type: 'category',
          show: false,
          labels: {
            show: false,
          },
          gridLines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
      cpuChartData: [
        {
          name: "CPU",
          data: [3, 4, 5, 1, 6, 7],
        },
      ],
      ramChartData: [
        {
          // labels: [],
          name: "RAM ",
          data: [30, 42, 15, 10, 60, 71],
        },
      ],
      eventsChartData: [
        {
          name: "Application installed",
          data: [
            {
              x: 1672757916,
              y: 1672757917,
              info: "Application installed",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Application opened",
          data: [
            {
              x: 1672757920,
              y: 1672757921,
              info: "Application opened",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Application updated",
          data: [
            {
              x: 1672757922,
              y: 1672757923,
              info: "Application updated",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Viewed signin screen",
          data: [
            {
              x: 1672757923,
              y: 1672757924,
              info: "Viewed signin screen",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Sign In",
          data: [
            {
              x: 1672757924,
              y: 1672757925,
              info: "Sign In",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Added item to cart",
          data: [
            {
              x: 1672757925,
              y: 1672757926,
              info: "Added item to cart",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Opened category page",
          data: [
            {
              x: 1672757926,
              y: 1672757927,
              info: "Opened category page",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Removed item from cart",
          data: [
            {
              x: 1672757929,
              y: 1672757930,
              info: "Removed item from cart",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Redirected from push notification",
          data: [
            {
              x: 1672757931,
              y: 1672757932,
              info: "Redirected from push notification",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Viewed order list",
          data: [
            {
              x: 1672757932,
              y: 1672757933,
              info: "Viewed order list",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Cancel order",
          data: [
            {
              x: 1672757933,
              y: 1672757934,
              info: "Cancel order",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Updated Shipping Address",
          data: [
            {
              x: 1672757933,
              y: 1672757934,
              info: "Updated Shipping Address",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Process Checkout",
          data: [
            {
              x: 1672757934,
              y: 1672757935,
              info: "Process Checkout",
              type: "event",
              timezone: "GMT",
            },
          ],
        },
      ],
      errorChartData: [
        {
          name: "ActivityNotFoundException",
          data: [
            {
              x: 1672757916,
              y: 1672757917,
              info: "ActivityNotFoundException",
              type: "error",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "Error converting byte to dex",
          data: [
            {
              x: 1672757919,
              y: 1672757920,
              info: "Error converting byte to dex",
              type: "error",
              timezone: "GMT",
            },
          ],
        },
        {
          name: "OutofMemoryError",
          data: [
            {
              x: 1672757921,
              y: 1672757922,
              info: "App crash",
              type: "error",
              timezone: "GMT",
            },
          ],
        },
      ],
      selectedEventDetail: {},
      networkSeries: [
        {
          name: "5G",
          data: [
            {
              x: "Network",
              info: "5G",
              timezone: "GMT",
              y: [1672757900, 1672757916],
            },
          ],
        },
        {
          name: "Wifi",
          data: [
            {
              x: "Network",
              info: "Wi-Fi",
              timezone: "GMT",
              y: [1672757916, 1672757928],
            },
          ],
        },
        {
          name: "2G",
          data: [
            {
              x: "Network",
              info: "2G",
              timezone: "GMT",
              y: [1672757928, 1672757935],
            },
          ],
        },
        {
          name: "3G",
          data: [
            {
              x: "Network",
              info: "3G",
              timezone: "GMT",
              y: [1672757935, 1672757940],
            },
          ],
        },
        {
          name: "4g",
          data: [
            {
              x: "Network",
              info: "4G",
              timezone: "GMT",
              y: [1672757940, 1672757948],
            },
          ],
        },
        {
          name: "No Internet",
          data: [
            {
              x: "Network",
              info: "No Internet",
              timezone: "GMT",
              y: [1672757948, 1672757960],
            },
          ],
        },
        {
          name: "Unknown",
          data: [
            {
              x: "Network",
              info: "Unknown",
              timezone: "GMT",
              y: [1672757960, 1672757978],
            },
          ],
        },
      ],
      batterySeries: [
        {
          name: "Charging",
          data: [
            {
              x: "Battery",
              info: "Charging",
              percentage: 80,
              power_mode: false,
              timezone: "GMT",
              y: [1672757910, 1672757920],
            },
          ],
        },
        {
          name: "Unknown",
          data: [
            {
              x: "Battery",
              info: "Unknown",
              percentage: 80,
              power_mode: false,
              timezone: "GMT",
              y: [1672757920, 1672757928],
            },
          ],
        },
        {
          name: "Unplugged",
          data: [
            {
              x: "Battery",
              info: "Unplugged",
              percentage: 56,
              power_mode: false,
              timezone: "GMT",
              y: [1672757928, 1672757935],
            },
          ],
        },
        {
          name: "Full",
          data: [
            {
              x: "Battery",
              info: "Full",
              percentage: 100,
              power_mode: false,
              timezone: "GMT",
              y: [1672757935, 1672757948],
            },
          ],
        },
      ],
      chartOptions: {
        chart: {
          type: "rangeBar",
          id: "rangeChart",
          group: "",
          width: "100%",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return data.info;
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          show: false,
        },
        tooltip: {
          custom: function ({ seriesIndex, y1, y2, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const start = dayjs(y1 * 1000).format("hh:mm:ss a");
            const end = dayjs(y2 * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${data.info}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <ul>
                      <li >Battery: ${data.percentage || ""} %</li>
                      <li >Low power: ${data.power_mode}</li>
                      <li>Time: ${start + " - " + end} ${data.timezone}</li>
                    </ul>

                  </v-card-text>
               </v-card>`;
          },
        },
      },
      networkOptions: {
        chart: {
          type: "rangeBar",
          id: "rangeChart",
          group: "range",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return data.info;
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          startTime: "",
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          show: false,
        },
        tooltip: {
          custom: function ({ seriesIndex, y1, y2, w, dataPointIndex }) {
            let data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const start = dayjs(y1 * 1000).format("hh:mm:ss a");
            const end = dayjs(y2 * 1000).format("hh:mm:ss a");
            return `<v-card class="pa-2">
                 <v-card-title>
                    ${data.info}
                  </v-card-title>
                  <v-card-text>
                    <ul>
                      <li>${start + " " + data.timezone + " - " + end} ${
              data.timezone
            }</li>
                    </ul>
                  </v-card-text>
               </v-card>`;
          },
        },
      },
      labels: [],
      eventsDetailCard: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamPlanUsage: "getTeamPlanUsage",
    }),
    timeZone() {
      return this.activeFeedback?.session?.info?.device?.timezone;
    },
  },
  watch: {
    "activeFeedback.id"() {
      if (
        this.getTeamPlanUsage?.feedback?.sessions &&
        this.activeFeedback?.session?.id
      ) {
        this.setLineChartDataForCpu();
        this.setLineChartDataForRam();
        this.setBubbleChartDataForEvents();
        this.getNetworkUseData();
        this.getBatteryData();
      }
    },
  },
  created() {
    // this.setOptionCategories()
    if (
      this.getTeamPlanUsage?.feedback?.sessions &&
      this.activeFeedback?.session?.id
    ) {
      this.setLineChartDataForCpu();
      this.setLineChartDataForRam();
      this.setBubbleChartDataForEvents();
      this.getNetworkUseData();
      this.getBatteryData();
    }
  },
  mounted() {},
  beforeDestroy() {
    this.networkSeries = [];
    this.batterySeries = [];
    this.eventsChartData = [];
    this.cpuChartData[0].data = [];
    this.ramChartData[0].data = [];
    // this.apexOptions.xaxis.categories = []
    // this.ramChartOptions.xaxis.categories = []
  },
  methods: {
    setOptionCategories() {
      this.activeFeedback?.session?.probes?.forEach((data) => {
        let time =
          dayjs(data?.timestamp * 1000).format("hh:mm:ss a") +
          " " +
          this.timeZone;
        this.apexOptions?.xaxis?.categories?.push(time);
      });
    },
    setLineChartDataForRam() {
      this.ramChartData[0].data = [];
      this.ramChartOptions.xaxis.categories = [];
      this.activeFeedback?.session?.performance?.resources.forEach((data) => {
        let storage = this.formatBytes(data?.data?.ram?.used / 1000);
        this.ramChartData[0]?.data?.push(parseInt(storage?.split(" ")[0]));
        let time =
          dayjs(data?.timestamp * 1000).format("hh:mm:ss a") +
          " " +
          this.timeZone;
        this.ramChartOptions?.xaxis?.categories.push(time);
      });
    },
    openDetailEventCard(detail, here, { seriesIndex }) {
      let eventsList = [];
      if (
        this.getTeamPlanUsage.feedback.sessions &&
        this.activeFeedback?.session?.id
      ) {
        eventsList = this.activeFeedback?.session?.events?.filter(
          (x) => x.type === "event"
        );
      } else {
        eventsList = [
          {
            name: "Application installed",
            parameters: {
              build: "10016",
              version: "3.0",
            },
            type: "event",
          },
          {
            name: "Application opened",
            parameters: {
              build: "10016",
              from_background: true,
              version: "3.0",
            },
            type: "event",
          },
          {
            name: "Application updated ",
            parameters: {
              build: "10016",
              previous_build: "27",
              previous_version: "2.0",
              version: "3.0",
            },
            type: "event",
          },
          {
            name: "Viewed signin screen",
            parameters: {},
            type: "event",
          },
          {
            name: "Sign In",
            parameters: {
              redirected_to: "dashboard",
            },
            type: "event",
          },
          {
            name: "Added item to cart",
            parameters: {
              product_id: 1,
              current_cart_count: 4,
            },
            type: "event",
          },
          {
            name: "Opened category page",
            parameters: {
              product_id: 1,
              category: "Electronics",
            },
            type: "event",
          },
          {
            name: "Removed item from cart",
            parameters: {
              product_id: 1,
              current_cart_count: 3,
            },
            type: "event",
          },
          {
            name: "Redirected from push notification",
            parameters: {
              type: "news",
              message_id: 394,
            },
            type: "event",
          },
          {
            name: "Viewed order list",
            parameters: {
              user_id: 33,
              total_list: 3,
            },
            type: "event",
          },
          {
            name: "Cancel order",
            parameters: {
              user_id: 33,
              order_id: 4,
            },
            type: "event",
          },
          {
            name: "Updated Shipping Address",
            parameters: {
              list: 12,
              user_id: "33",
            },
            type: "event",
          },
          {
            name: "Process Checkout",
            parameters: {
              checkout_id: 12,
              user_id: "33",
            },
            type: "event",
          },
        ];
      }
      this.selectedEventDetail = eventsList[seriesIndex];
      this.eventsDetailCard = true;
    },
    openDetailErrorCard(detail, here, { seriesIndex }) {
      let errorList = [];
      if (
        this.getTeamPlanUsage.feedback.sessions &&
        this.activeFeedback?.session?.id
      ) {
        errorList = this.activeFeedback?.session?.events?.filter(
          (x) => x.type === "error"
        );
      } else {
        errorList = [
          {
            name: "ActivityNotFoundException",
            parameters: {
              startActivity: "Fail",
              error: "cannot find .open of undefined",
            },
            type: "error",
          },
          {
            name: "Error converting byte to dex",
            parameters: {
              build_project: "not found",
            },
            type: "error",
          },
          {
            name: "OutofMemoryError",
            parameters: {
              error: "not sufficient storage",
            },
            type: "error",
          },
        ];
      }
      this.selectedEventDetail = errorList[seriesIndex];
      this.eventsDetailCard = true;
    },
    setLineChartDataForCpu() {
      this.cpuChartData[0].data = [];
      this.apexOptions.xaxis.categories = [];
      this.activeFeedback?.session?.performance?.resources?.forEach((data) => {
        this.cpuChartData[0]?.data?.push(data?.data?.cpu?.toFixed(2));
        let time =
          dayjs(data?.timestamp * 1000).format("hh:mm:ss a") +
          " " +
          this.timeZone;
        this.apexOptions?.xaxis?.categories.push(time);
      });
    },
    setBubbleChartDataForEvents() {
      this.eventsChartData = [];
      this.errorChartData = [];
      this.activeFeedback?.session?.events.forEach((data) => {
        if (data.type === "event") {
          this.eventsChartData.push({
            name: data.type,
            data: [
              {
                x: data.timestamp,
                y: data.timestamp + 1,
                info: data.name,
                type: data.type,
                timezone: this.timeZone,
              },
            ],
          });
        } else {
          this.errorChartData.push({
            name: data.type,
            data: [
              {
                x: data.timestamp,
                y: data.timestamp + 1,
                info: data.name,
                type: data.type,
                timezone: this.timeZone,
              },
            ],
          });
        }

        this.eventOptions?.xaxis.categories.push(data.timestamp);
        // let time = dayjs(data?.timestamp ).format("hh:mm:ss a")
      });
    },
    getNetworkUseData() {
      this.networkSeries = [];
      this.activeFeedback?.session?.performance?.connectivity.forEach(
        (data, index) => {
          let endTimestamp =
            this.activeFeedback?.session?.performance?.resources[
              this.activeFeedback?.session?.performance?.resources.length - 1
            ].timestamp;
          let timestamp = data?.timestamp;
          let startTime =
            this.activeFeedback?.session?.time_interval?.start_date;
          let networkInfos = {
            wifi: "Wi-Fi",
            noInternet: "No Internet",
            "4g": "4G",
            "3g": "3G",
            "2g": "2G",
            unknown: "Unknown",
            "5g": "5G",
          };
          this.networkOptions.xaxis.startTime = startTime;
          // this.networkOptions.yaxis.max = endTimestamp
          this.networkSeries.push({
            data: [
              {
                x: "Network",
                info: networkInfos[data?.data],
                timezone: this.timeZone,
                y: [
                  index === 0 ? startTime : timestamp,
                  this.activeFeedback?.session?.performance?.connectivity[
                    index + 1
                  ]?.timestamp || endTimestamp,
                ],
              },
            ],
          });
        }
      );
    },
    getBatteryData() {
      this.batterySeries = [];
      this.activeFeedback?.session?.performance?.battery?.forEach(
        (data, index) => {
          let endTimestamp =
            this.activeFeedback?.session?.performance?.resources[
              this.activeFeedback?.session?.performance?.resources.length - 1
            ].timestamp;
          let timestamp = data?.timestamp;
          this.batterySeries.push({
            name: data?.data?.state,
            data: [
              {
                x: "Battery",
                info: data?.data?.state,
                percentage: (data?.data?.percentage * 100).toFixed(2),
                power_mode: data?.data?.low_power,
                timezone: this.timeZone,
                y: [
                  timestamp,
                  this.activeFeedback?.session?.performance?.battery[index + 1]
                    ?.timestamp || endTimestamp,
                ],
              },
            ],
          });
        }
      );
    },
    getSessionDuration() {
      let startTime = dayjs(
        this.activeFeedback?.session?.time_interval?.start_date * 1000
      );
      let endTime = dayjs(
        this.activeFeedback?.session?.time_interval?.end_date * 1000
      );
      let diffMin = endTime.diff(startTime, "second");
      let seconds = diffMin;
      let min = (((seconds % 31536000) % 86400) % 3600) / 60;
      let sec = (((seconds % 31536000) % 86400) % 3600) % 60;
      return `${Math.floor(min)}m:${Math.floor(sec)}s`;
      // return `${startTime.format('hh:mm:ss a')} - ${endTime.format('hh:mm:ss a')}`
    },
  },
};
</script>

<style scoped>
.watermark {
  opacity: 0.2;
  color: BLACK;
  position: absolute;
  bottom: 65%;
  -webkit-transform: skewY(50deg);
  transform: skew(-20deg, 30deg);
  font-size: 70px;
  right: 20%;
}

.secondwatermark {
  opacity: 0.2;
  color: BLACK;
  position: absolute;
  bottom: 20%;
  -webkit-transform: skewY(50deg);
  transform: skew(-20deg, 30deg);
  font-size: 70px;
  right: 20%;
}

.chart-wrapper {
  border: 1px solid blue;
  height: 100px;
  width: 600px;
}
</style>