var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "60%"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1)], 1), _c('v-card-text', [_c('v-container', {
    staticClass: "scr"
  }, [_vm.type === 'video/mp4' ? _c('video', {
    staticClass: "pointer",
    attrs: {
      "width": "100%",
      "controls": "",
      "height": "350"
    }
  }, [_c('source', {
    staticClass: "max-height-350",
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "src": _vm.src,
      "type": "video/mp4"
    }
  })]) : _c('img', {
    staticClass: "my-3 contain",
    staticStyle: {
      "max-height": "700px",
      "object-fit": "contain"
    },
    attrs: {
      "src": _vm.src,
      "alt": "empty",
      "width": "100%"
    },
    on: {
      "error": _vm.replaceDefaultImage
    }
  })])], 1), _c('v-card-actions')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }