var render = function render(){
  var _vm$activeFeedback, _vm$defaultImage2, _vm$defaultImage2$ses, _vm$defaultImage3, _vm$defaultImage3$ses, _vm$defaultImage4, _vm$defaultImage4$ses, _vm$defaultImage5, _vm$defaultImage6, _vm$defaultImage6$pla, _vm$defaultImage7, _vm$defaultImage7$pla, _vm$defaultImage8, _vm$defaultImage9, _vm$defaultImage10, _vm$defaultImage11, _vm$defaultImage12, _vm$defaultImage13;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "mb-5 mx-2 bold-text subListFont"
  }, [_vm._v(" Attachment "), _c('span', {
    staticClass: "float-right top"
  }, [((_vm$activeFeedback = _vm.activeFeedback) === null || _vm$activeFeedback === void 0 ? void 0 : _vm$activeFeedback.status) !== 3 && _vm.canManageApp && _vm.attachmentList.length ? _c('action-dropdown', {
    attrs: {
      "id": "feedbackAction"
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('v-list-item', {
          on: {
            "click": function click($event) {
              var _vm$defaultImage;
              return _vm.openDeleteVideo((_vm$defaultImage = _vm.defaultImage) === null || _vm$defaultImage === void 0 ? void 0 : _vm$defaultImage.id);
            }
          }
        }, [_c('v-icon', {
          staticClass: "info-color",
          attrs: {
            "small": "",
            "left": ""
          }
        }, [_vm._v("delete")]), _c('div', {
          staticClass: "regular-font font-14 info-color"
        }, [_vm._v("Delete")])], 1)];
      },
      proxy: true
    }], null, false, 3483689141)
  }) : _vm._e()], 1)]), _vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "article"
    }
  })], 1)], 1) : !_vm.attachmentList.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": "/media/error/no_file.svg",
      "alt": "empty",
      "height": "200",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "text-center subListFont py-4"
  }, [_vm._v("No attachments")])])], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm.getFeedbackLoader ? _c('v-overlay', {
    attrs: {
      "value": true,
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "width": "8",
      "size": "100",
      "indeterminate": ""
    }
  }, [_vm._v(" " + _vm._s(100 > _vm.getTotalProgress ? _vm.getTotalProgress : "Uploading") + " ")])], 1) : _vm._e(), !_vm.getDataFromFeedbackSocket.length && !_vm.attachmentList.length ? _c('div', {
    staticStyle: {
      "min-height": "350px !important"
    }
  }, [_c('img', {
    staticClass: "max-height-350",
    attrs: {
      "src": "/media/error/no_file.svg",
      "alt": "empty",
      "width": "100%"
    }
  })]) : _c('carousel', {
    attrs: {
      "activeFeedback": _vm.getActiveAttachmentList,
      "src": _vm.src,
      "dialog": _vm.dialog,
      "selectedIndex": _vm.selectedIndex,
      "from-page": _vm.fromPage
    },
    on: {
      "selectFile": _vm.selectFile,
      "setSelectedIndex": _vm.selectFile,
      "attachmentExtension": _vm.attachmentExtension
    }
  })], 1), _vm.expansion.includes(4) && (!_vm.getTeamPlanUsage.feedback.sessions || !((_vm$defaultImage2 = _vm.defaultImage) !== null && _vm$defaultImage2 !== void 0 && (_vm$defaultImage2$ses = _vm$defaultImage2.session) !== null && _vm$defaultImage2$ses !== void 0 && _vm$defaultImage2$ses.id)) ? _c('v-card', {
    staticClass: "mt-15 pa-2"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [!((_vm$defaultImage3 = _vm.defaultImage) !== null && _vm$defaultImage3 !== void 0 && (_vm$defaultImage3$ses = _vm$defaultImage3.session) !== null && _vm$defaultImage3$ses !== void 0 && _vm$defaultImage3$ses.id) && _vm.getTeamPlanUsage.feedback.sessions ? _c('div', [_vm._v(" Use TestApp.io SDK to collect valuable information. ")]) : _c('div', [_c('div', [_c('v-avatar', {
    attrs: {
      "height": "80",
      "width": "80"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-lock')
    }
  })], 1)], 1), _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Unlock valuable insights and improve your app's performance with TestApp.io's advanced feedback session data. "), _c('br'), _c('br'), _vm._v("Upgrade today to take your mobile app testing to the next level. ")])])]), _c('v-col', {
    staticClass: "text-center"
  }, [!((_vm$defaultImage4 = _vm.defaultImage) !== null && _vm$defaultImage4 !== void 0 && (_vm$defaultImage4$ses = _vm$defaultImage4.session) !== null && _vm$defaultImage4$ses !== void 0 && _vm$defaultImage4$ses.id) && _vm.getTeamPlanUsage.feedback.sessions ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "target": "_blank",
      "href": "https://help.testapp.io/about-sdk/",
      "text": "",
      "color": "primary"
    }
  }, [_vm._v(" Learn more... ")]) : _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openStartTrialPopup = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-lock')
    }
  }), _vm._v(" Unlock sessions ")], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-row', {
    class: {
      'mx-2': _vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-expansion-panels', {
    attrs: {
      "flat": "",
      "multiple": ""
    },
    model: {
      value: _vm.expansion,
      callback: function callback($$v) {
        _vm.expansion = $$v;
      },
      expression: "expansion"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "bold-text darkGrey--text"
  }, [_vm._v("File")])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "v-card v-sheet v-sheet--outlined hide-v-data-footer__select",
    attrs: {
      "headers": _vm.fileDetailHeader,
      "items": [(_vm$defaultImage5 = _vm.defaultImage) === null || _vm$defaultImage5 === void 0 ? void 0 : _vm$defaultImage5.file],
      "mobile-break-point": "0",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var _item$name;
        var item = _ref.item;
        return [item !== null && item !== void 0 && item.name ? _c('div', [(item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name.length) > 20 ? _c('div', [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var _item$name2;
              var on = _ref2.on;
              return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$name2 = item.name) === null || _item$name2 === void 0 ? void 0 : _item$name2.substring(0, 20)) + "...") + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])])], 1) : _c('div', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])]) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "item.size",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatBytes(item === null || item === void 0 ? void 0 : item.size)))])];
      }
    }, {
      key: "item.height",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(item !== null && item !== void 0 && item.height ? (item === null || item === void 0 ? void 0 : item.height) + "px" : "-"))])];
      }
    }, {
      key: "item.width",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(item !== null && item !== void 0 && item.width ? (item === null || item === void 0 ? void 0 : item.width) + "px" : "-"))])];
      }
    }, {
      key: "item.duration",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.getDuration(item)) + " ")];
      }
    }])
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "bold-text darkGrey--text"
  }, [_vm._v("App")])]), _c('v-expansion-panel-content', [['Portal', 'App'].includes((_vm$defaultImage6 = _vm.defaultImage) === null || _vm$defaultImage6 === void 0 ? void 0 : (_vm$defaultImage6$pla = _vm$defaultImage6.platform) === null || _vm$defaultImage6$pla === void 0 ? void 0 : _vm$defaultImage6$pla.name) ? _c('no-session-data') : _c('v-data-table', {
    staticClass: "v-card v-sheet v-sheet--outlined hide-v-data-footer__select",
    attrs: {
      "headers": _vm.appDetailHeader,
      "items": _vm.getAppInformation,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.name) || "-"))])];
      }
    }, {
      key: "item.package",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.package) || "-"))])];
      }
    }, {
      key: "item.version",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.version) || "-"))])];
      }
    }, {
      key: "item.version_code",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.version_code) || "-"))])];
      }
    }])
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "bold-text darkGrey--text"
  }, [_vm._v("Device")])]), _c('v-expansion-panel-content', [((_vm$defaultImage7 = _vm.defaultImage) === null || _vm$defaultImage7 === void 0 ? void 0 : (_vm$defaultImage7$pla = _vm$defaultImage7.platform) === null || _vm$defaultImage7$pla === void 0 ? void 0 : _vm$defaultImage7$pla.name) === 'Portal' ? _c('no-session-data') : _c('v-data-table', {
    staticClass: "v-card v-sheet v-sheet--outlined hide-v-data-footer__select",
    attrs: {
      "headers": _vm.deviceDetailHeader,
      "items": _vm.getDeviceInformation,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.manufacturer",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.manufacturer) || "-"))])];
      }
    }, {
      key: "item.model",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [(item === null || item === void 0 ? void 0 : item.manufacturer) == 'Apple' ? _c('div', [_vm._v(" " + _vm._s(_vm.appDeviceList[item === null || item === void 0 ? void 0 : item.model]) + " ")]) : _c('div', [_vm._v(" " + _vm._s(item.model) + " ")])];
      }
    }, {
      key: "item.os",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.os) || "-") + " ")])];
      }
    }, {
      key: "item.os_version",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.os_version))])];
      }
    }, {
      key: "item.ram",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.type) + " ")])];
      }
    }, {
      key: "item.locale",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.locale) || "-") + " ")];
      }
    }])
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "bold-text darkGrey--text"
  }, [_vm._v("Platform")])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "v-card v-sheet v-sheet--outlined hide-v-data-footer__select",
    attrs: {
      "headers": _vm.platformDetailHeader,
      "items": [(_vm$defaultImage8 = _vm.defaultImage) === null || _vm$defaultImage8 === void 0 ? void 0 : _vm$defaultImage8.platform],
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.size",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.version) || "-"))])];
      }
    }, {
      key: "item.height",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.version_code) || "-"))])];
      }
    }])
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "bold-text darkGrey--text"
  }, [_vm._v("Session")])]), _c('v-expansion-panel-content', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('session-display-chart', {
    key: (_vm$defaultImage9 = _vm.defaultImage) === null || _vm$defaultImage9 === void 0 ? void 0 : _vm$defaultImage9.id,
    attrs: {
      "active-feedback": _vm.defaultImage
    }
  })], 1)], 1)], 1), (_vm$defaultImage10 = _vm.defaultImage) !== null && _vm$defaultImage10 !== void 0 && _vm$defaultImage10.id ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "bold-text darkGrey--text"
  }, [_vm._v("Internal Notes")])]), _c('v-expansion-panel-content', [(_vm$defaultImage11 = _vm.defaultImage) !== null && _vm$defaultImage11 !== void 0 && _vm$defaultImage11.id ? _c('v-row', {
    staticClass: "mx-2"
  }, [_c('v-col', {
    staticClass: "regular-font font-12",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.openAddNoteModal ? _c('div', [(_vm$defaultImage12 = _vm.defaultImage) !== null && _vm$defaultImage12 !== void 0 && _vm$defaultImage12.note ? _c('div', [_c('span', {
    staticClass: "float-right top"
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-pencil')
    },
    on: {
      "click": function click($event) {
        return _vm.editAttachmentNote(_vm.defaultImage);
      }
    }
  })], 1), _c('vue-readmore', {
    attrs: {
      "text": (_vm$defaultImage13 = _vm.defaultImage) === null || _vm$defaultImage13 === void 0 ? void 0 : _vm$defaultImage13.note,
      "length": 100
    }
  })], 1) : _c('div', [_vm.canManageFeedback ? _c('span', [_c('span', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.editAttachmentNote(_vm.defaultImage);
      }
    }
  }, [_vm._v("Click here")]), _vm._v(" to update note ")]) : _c('span', [_vm._v("-")])])]) : _vm._e(), _vm.openAddNoteModal ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rows": "2",
      "auto-grow": "",
      "filled": "",
      "row-height": "2"
    },
    model: {
      value: _vm.attachmentNote,
      callback: function callback($$v) {
        _vm.attachmentNote = $$v;
      },
      expression: "attachmentNote"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3 text-transform-none",
    attrs: {
      "disabled": _vm.busy,
      "color": "primary",
      "text": "",
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        _vm.openAddNoteModal = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "mr-3 text-transform-none",
    attrs: {
      "loading": _vm.busy,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "depressed": ""
    },
    on: {
      "click": _vm.submitAttachmentNote
    }
  }, [_vm._v(" Update ")])], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.deleteAttachmentModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Delete attachment")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v("Are you sure you want to delete this attachment?")])]), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppCancelBtn",
            "color": "primary",
            "text": "",
            "disabled": _vm.busy
          },
          on: {
            "click": function click($event) {
              _vm.deleteAttachmentModal = false;
            }
          }
        }, [_vm._v("No")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "loading": _vm.busy
          },
          on: {
            "click": _vm.onConfirmDeleteAttachment
          }
        }, [_vm._v("Yes")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.deleteAttachmentModal,
      callback: function callback($$v) {
        _vm.deleteAttachmentModal = $$v;
      },
      expression: "deleteAttachmentModal"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-upgrade-component": "",
            "upgrade-plan-text": 'Upgrade your plan'
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": "",
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 900 : ''
    },
    on: {
      "close": function close($event) {
        _vm.openStartTrialPopup = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.openStartTrialPopup ? _c('start-trial-modal', {
          attrs: {
            "trigger-from": "feedbackSessions"
          },
          on: {
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            },
            "close": function close($event) {
              _vm.openStartTrialPopup = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openStartTrialPopup,
      callback: function callback($$v) {
        _vm.openStartTrialPopup = $$v;
      },
      expression: "openStartTrialPopup"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }