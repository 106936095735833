<template>
    <v-alert outlined>
        <div class=" text-center darkGrey--text">
            Use TestApp.io SDK to collect valuable information.
            <br />
            <a href="https://help.testapp.io/about-sdk/" target="_blank">
                Learn more...
            </a>
        </div>
    </v-alert>
</template>

<script>
export default {};
</script>
