var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [[1, 2].includes(_vm.activeFeedback.status) && _vm.canManageApp ? _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('openFlagging', _vm.activeFeedback.id, 3);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-flag-outline')
    }
  }), _vm._v(" Flag as Inappropriate ")], 1) : _vm._e(), [1].includes(_vm.activeFeedback.status) && _vm.canManageApp ? _c('v-list-item', {
    attrs: {
      "id": "markAsResoved"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('updateStatus', _vm.activeFeedback.id, 2);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Mark as Resolved ")], 1) : _vm._e(), [2].includes(_vm.activeFeedback.status) && _vm.canManageApp ? _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('updateStatus', _vm.activeFeedback.id, 1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Mark as Not Resolved ")], 1) : _vm._e(), _vm.activeFeedback.user.id === _vm.currentUser.id ? _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('openUpdateFeedback', _vm.activeFeedback);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")]), _vm._v(" update ")], 1) : _vm._e(), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('openDeleteDialog', _vm.activeFeedback.id);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v("delete")]), _vm._v(" Delete ")], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }