<template>
  <div>
    <div>
      <div class="card-img d-flex justify-center" style="min-height: 350px">
        <v-col cols="12" class="mt-14" v-if="fileLoading">
          <v-skeleton-loader type="image" />
        </v-col>
        <div>
          <div
            style="min-height: 350px !important"
            v-if="currentFileType === ''"
          >
            <img
              class="max-height-350"
              src="/media/error/no_file.svg"
              alt="empty"
              width="100%"
            />
          </div>
          <!--          <div-->
          <!--            class="delete-modal"-->
          <!--            style="position: relative"-->
          <!--            v-else-if="-->
          <!--              currentImageExtension === '.mp4' ||-->
          <!--              currentImageExtension === '.MOV' ||-->
          <!--              currentImageExtension === 'mp4'-->
          <!--            "-->
          <!--          >-->
          <!--            <video width="100%" controls class="pointer" height="350">-->
          <!--              <source-->
          <!--                style="height: 350px"-->
          <!--                :src="currentImage"-->
          <!--                type="video/mp4"-->
          <!--                @error="replaceDefaultImage"-->
          <!--                class="max-height-350"-->
          <!--              />-->
          <!--            </video>-->
          <!--          </div>-->
          <div
            v-else
            style="position: relative"
            class="big-v-img"
            @click="resizeImage()"
          >
            <img
              style="object-fit: cover; height: 350px"
              class="max-height-350"
              :src="currentImage"
              alt="empty"
              width="100%"
              @error="replaceDefaultImage"
            />
            <div class="img-background max-height-350"></div>
            <v-icon
              v-if="currentFileType === 'video/mp4'"
              class="cursor-pointer"
              style="position: absolute; top: 40%; left: 30%"
              size="60"
              color="white"
              v-text="'mdi-play-circle-outline'"
            ></v-icon>
            <img src="/media/feedback/showBig.svg" alt="" class="img-icon" />
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-center align-center "
        v-if="activeFeedback[0]?.file?.url"
      >
        <button class="btn-action" @click="prev">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
            />
          </svg>
        </button>
        <div class="carousel mt-4" :class="{ showCenter: attachmentCenter }">
          <div class="inner" ref="inner" :style="innerStyles">
            <div v-for="(attachment, index) in images" :key="index">
              <div
                :class="[activeImage === index ? 'active' : '']"
                class="card"
                v-if="attachment.thumb"
                @click="activateImage(index)"
              >
                <div @click="$emit('selectFile', index)">
                  <v-avatar
                    ml-2
                    class="top float-right"
                    size="15"
                    tile
                    color="white"
                  >
                    <v-icon
                      class=" "
                      color="primary"
                      small
                      v-text="
                        attachment?.mime === 'video/mp4'
                          ? 'mdi-message-video'
                          : 'mdi-image'
                      "
                    >
                    </v-icon>
                  </v-avatar>

                  <img
                    style="
                      object-fit: cover;
                      width: 40px;
                      height: 34px;
                      padding-top: 7px;
                    "
                    class="pointer"
                    :src="attachment.thumb"
                    alt="empty"
                    @error="replaceDefaultImage"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="btn-action" @click="next">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
            />
          </svg>
        </button>
      </div>
      <img-modal
        :dialog="dialog"
        :src="currentBigImage"
        :type="currentFileType"
        @close="dialog = false"
        @openDeleteAttachmentModal="deleteAttachmentModal = true"
        v-if="dialog"
      ></img-modal>
    </div>
  </div>
</template>

<script>
import imgModal from "@/view/components/Common/ImgModal";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { imgModal },
  props: {
    activeFeedback: {
      type: Array,
    },
    fromPage: {
      type: Boolean,
      default: false,
    },
    selectedIndex: {
      type: Number,
    },
    src: {
      type: String,
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerStyles: {},
      attachmentCenter: false,
      showOverlay: false,
      step: "",
      transitioning: false,
      fileLoading: false,
      index: 0,
      type: "",
      imagesAternative: [],
      activeImage: 0,
      images: this.activeFeedback.map((image) => {
        return {
          id: image?.file.id,
          big: image?.file?.url,
          thumb: image?.file?.thumbnail,
          status: 1,
          mime: image?.file?.mime,
          extension: image?.extension
            ? image?.extension
            : image?.file?.name?.split(".")?.pop(),
        };
      }),
    };
  },
  watch: {
    activeFeedback: {
      handler: function(val) {
        this.images = val.map((image) => {
          return {
            id: image?.id,
            big: image?.file?.url,
            thumb: image?.file?.thumbnail,
            url: image?.file?.url,
            status: 1,
            mime: image?.file?.mime,
            extension: image?.extension
              ? image?.extension
              : image?.file?.name?.split(".")?.pop(),
          };
        });
        this.activeImage = 0;
        this.attachmentCenter = val.length <= 2;
      },
      deep: true,
      immediate: true,
    },
    uploadProgress(val) {
      if (!val) {
        this.setFeedbackLoader(false);
      }
    },
    getFeedbackLoader() {
      this.next();
    },
    getFeedbackDataFromSocket: {
      handler: function(val) {
        val?.forEach((image) => {
          if (image.status === 1) {
            this.next();
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      getDataFromFeedbackSocket: "getDataFromFeedbackSocket",
      getAttachmentThumbnailList: "getAttachmentThumbnailList",
      getTotalProgress: "getTotalProgress",
      getAttachmentsList: "getAttachmentsList",
      getFeedbackLoader: "getFeedbackLoader",
    }),
    uploadProgress() {
      const arrUniq = [
        ...new Map(this.getAttachmentsList.map((v) => [v.type, v])).values(),
      ];
      const feedbackCount =
        arrUniq.filter((x) => x.status === "uploading").length || 0;
      return feedbackCount || 0;
    },
    currentImage() {
      if (this.$route.params.attachment_id && !this.fromPage) {
        let image = this.images.find(
          (x) => x.id === this.$route.params.attachment_id
        );
        return this.currentFileType === "video/mp4" ? image?.thumb : image?.url;
      } else {
        return this.currentFileType === "video/mp4"
          ? this.images[this.activeImage]?.thumb
          : this.images[this.activeImage]?.url;
      }
    },
    currentFileType() {
      return this.images[this.activeImage]?.mime;
    },
    currentImageStatus() {
      return this.images[this.activeFeedback]?.status;
    },
    currentBigImage() {
      return this.images[this.activeImage]?.big;
    },
    currentImageExtension() {
      this.$emit(
        "attachmentExtension",
        this.activeFeedback[this.activeImage]?.file?.extension
      );
      return this.images[this.activeImage]?.file?.extension;
    },
    attachmentExtension() {
      return this.attachmentStatus?.url?.split(".").pop();
    },
  },

  mounted() {
    this.setStep();
    this.resetTranslate();
    if (this.$route.params.attachment_id && !this.fromPage) {
      let imagesId = this.images.map((x) => x.id);
      this.activeImage = imagesId.indexOf(this.$route.params.attachment_id);
    }
  },
  methods: {
    ...mapMutations({
      setPreviousReleaseFeedback: "setPreviousReleaseFeedback",
      updateLatestReleaseFeedbackCount: "updateLatestReleaseFeedbackCount",
      updateInstallFeedbackStatus: "updateInstallFeedbackStatus",
      removeDeletedRelease: "removeDeletedRelease",
      clearAttachmentList: "clearAttachmentList",
      setFeedbackLoader: "setFeedbackLoader",
    }),
    replaceDefaultImage(e) {
      e.target.src = "/media/error/no_file.svg";
    },
    resizeImage() {
      this.dialog = true;
      // this.$emit("imageId", image.id);
    },

    activateImage(imageIndex) {
      this.fileLoading = true;
      this.activeImage = imageIndex;
      setTimeout(() => {
        this.fileLoading = false;
      }, 1500);
    },
    setStep() {
      this.index = this.selectedIndex;
      const innerWidth = this.$refs?.inner?.scrollWidth;
      const totalCards = this.activeFeedback.length;
      if (this.activeFeedback.length >= 3) {
        this.attachmentCenter = true;
        this.step = `${innerWidth / totalCards}px`;
      } else {
        if (this.activeFeedback.length <= 2) {
          this.attachmentCenter = true;
        }
        this.step = `${0}px`;
      }
    },
    next() {
      if (this.transitioning) return;
      this.transitioning = true;
      let active = this.activeImage + 1;
      if (active >= this.images.length) {
        active = 0;
      }
      this.moveLeft();

      this.$emit("setSelectedIndex", active);
      this.afterTransition(() => {
        const card = this.activeFeedback.shift();
        this.activeFeedback.push(card);
        this.resetTranslate();
        this.transitioning = false;
        this.activateImage(active);
      });
    },
    prev() {
      if (this.transitioning) return;
      this.transitioning = true;
      let active = this.activeImage - 1;
      if (active < 0) {
        active = this.images.length - 1;
      }
      this.$emit("setSelectedIndex", active);
      this.moveRight();

      this.afterTransition(() => {
        const card = this.activeFeedback.pop();
        this.activeFeedback.unshift(card);
        this.resetTranslate();
        this.transitioning = false;
        this.activateImage(active);
      });
    },
    moveLeft() {
      this.innerStyles = {
        transform: `translateX(-${this.step})
                    translateX(-${this.step})`,
      };
    },
    moveRight() {
      this.innerStyles = {
        transform: `translateX(${this.step})
                    translateX(-${this.step})`,
      };
    },
    afterTransition(callback) {
      const listener = () => {
        callback();
        this.$refs?.inner?.removeEventListener("transitionend", listener);
      };
      this.$refs?.inner?.addEventListener("transitionend", listener);
    },
    resetTranslate() {
      this.innerStyles = {
        transition: "none",
        transform: `translateX(-${this.step})`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.carousel {
  display: flex;
  width: 250px;
  overflow: hidden;
}
.inner {
  transition: transform 0.2s;
  white-space: nowrap;
  display: contents;
}
.card {
  width: 45px;
  margin-right: 10px;
  display: inline-flex;
  height: 40px;
  color: white;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.btn-action {
  margin-right: 5px;
  margin-top: 10px;
}
.img-icon {
  position: absolute;
  bottom: 3px;
  right: 10px;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s ease;
}
.img-background {
  background-color: #131418;
  cursor: pointer;
  opacity: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
}
.big-v-img:hover .img-icon {
  opacity: 1 !important;
}
.big-v-img:hover .img-background {
  opacity: 0.4 !important;
}
.max-height-350 {
  max-height: 350px;
}
.active {
  border: 1px solid #4062d7;
  border-radius: 10px;
}
.showCenter {
  justify-content: center;
}
.uploading-attachment {
  height: 350px;
  max-height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #0e48dd;
}
</style>
