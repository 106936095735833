var render = function render(){
  var _vm$activeFeedback, _vm$activeFeedback2, _vm$activeFeedback3, _vm$activeFeedback3$u, _vm$activeFeedback4, _vm$activeFeedback4$u, _vm$activeFeedback5, _vm$activeFeedback5$u, _vm$activeFeedback6, _vm$activeFeedback6$u, _vm$activeFeedback7, _vm$activeFeedback7$u, _vm$activeFeedback8, _vm$activeFeedback8$u;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "mb-5 mx-3 subListFont bold-text"
  }, [_vm._v(" Information "), _c('span', {
    staticClass: "float-right top"
  }, [(_vm$activeFeedback = _vm.activeFeedback) !== null && _vm$activeFeedback !== void 0 && _vm$activeFeedback.id && _vm.activeFeedback.status !== 3 ? _c('action-dropdown', {
    attrs: {
      "id": "feedbackAction"
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('feedback-dropdown-item', {
          attrs: {
            "activeFeedback": _vm.activeFeedback,
            "currentUser": _vm.currentUser
          },
          on: {
            "openDeleteDialog": function openDeleteDialog($event) {
              return _vm.$emit('openDeleteDialog', _vm.activeFeedback.id);
            },
            "openFlagging": _vm.openFlagging,
            "openUpdateFeedback": function openUpdateFeedback($event) {
              return _vm.$emit('openUpdateFeedback', _vm.activeFeedback);
            },
            "updateStatus": _vm.updateStatus
          }
        })];
      },
      proxy: true
    }], null, false, 3005867209)
  }) : _vm._e()], 1)]), (_vm$activeFeedback2 = _vm.activeFeedback) !== null && _vm$activeFeedback2 !== void 0 && _vm$activeFeedback2.id ? _c('v-row', {}, [_c('v-col', {
    staticClass: "py-0 pb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "v-card v-sheet v-sheet--outlined hide-v-data-footer__select",
    attrs: {
      "expanded": _vm.expanded,
      "headers": _vm.informationDetailHeader,
      "items": [_vm.activeFeedback],
      "hide-default-footer": "",
      "single-expand": ""
    },
    scopedSlots: _vm._u([{
      key: "item.country",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', [_c('country-city', {
          staticClass: "mt-2 ml-3 regular-font font-12 line-height-16",
          attrs: {
            "detail": item
          }
        })], 1)];
      }
    }, {
      key: "item.release",
      fn: function fn(_ref2) {
        var _vm$$options$RELEASE_, _item$release2, _item$release3;
        var item = _ref2.item;
        return [item.release && item.release.status ? _c('div', [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var _item$release;
              var on = _ref3.on;
              return [_c('span', _vm._g({}, on), [_c('v-icon', {
                attrs: {
                  "color": _vm.$options.RELEASE_STATUS[item.release.status].color,
                  "left": "",
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s((item === null || item === void 0 ? void 0 : (_item$release = item.release) === null || _item$release === void 0 ? void 0 : _item$release.platform) === 'android' ? 'mdi-android' : 'mdi-apple')
                }
              })], 1)];
            }
          }], null, true)
        }, [_c('span', [_c('ul', [_c('li', [_vm._v(" Status: " + _vm._s((_vm$$options$RELEASE_ = _vm.$options.RELEASE_STATUS[item === null || item === void 0 ? void 0 : (_item$release2 = item.release) === null || _item$release2 === void 0 ? void 0 : _item$release2.status]) === null || _vm$$options$RELEASE_ === void 0 ? void 0 : _vm$$options$RELEASE_.title) + " ")]), _c('li', [_vm._v(" Version: " + _vm._s(item.release.version) + " ")]), _c('li', [_vm._v(" Version Code: " + _vm._s(item.release.version_code) + " ")]), _c('li', [_vm._v(" Size: "), (item === null || item === void 0 ? void 0 : (_item$release3 = item.release) === null || _item$release3 === void 0 ? void 0 : _item$release3.size) >= 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.formatBytes(item.release.size)) + " ")]) : _vm._e()])])])])], 1) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref4) {
        var _vm$$options$STATUS$i3;
        var item = _ref4.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var _vm$$options$STATUS$i, _vm$$options$STATUS$i2;
              var on = _ref5.on;
              return [_c('span', _vm._g({}, on), [_c('v-icon', {
                attrs: {
                  "color": (_vm$$options$STATUS$i = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i === void 0 ? void 0 : _vm$$options$STATUS$i.color
                },
                domProps: {
                  "textContent": _vm._s((_vm$$options$STATUS$i2 = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i2 === void 0 ? void 0 : _vm$$options$STATUS$i2.icon)
                }
              })], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s((_vm$$options$STATUS$i3 = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i3 === void 0 ? void 0 : _vm$$options$STATUS$i3.title) + " ")])])];
      }
    }, {
      key: "item.note",
      fn: function fn(_ref6) {
        var item = _ref6.item,
          expand = _ref6.expand,
          isExpanded = _ref6.isExpanded;
        return [_c('div', [_vm.activeFeedback.note ? _c('div', [_vm.canManageFeedback ? _c('span', {
          staticClass: "float-right top"
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s('mdi-pencil')
          },
          on: {
            "click": function click($event) {
              return _vm.expendRow(item);
            }
          }
        })], 1) : _vm._e(), _c('vue-readmore', {
          attrs: {
            "length": 300,
            "text": _vm.activeFeedback.note || '-'
          }
        })], 1) : _c('div', [_vm.canManageFeedback ? _c('span', [_c('span', {
          staticClass: "primary--text cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.expendRow(item);
            }
          }
        }, [_vm._v("Click here")]), _vm._v(" to update note ")]) : _c('span', [_vm._v(" -")])])])];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref7) {
        var headers = _ref7.headers,
          item = _ref7.item,
          expand = _ref7.expand,
          isExpanded = _ref7.isExpanded;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-card', {
          staticClass: "pa-4"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-textarea', {
          attrs: {
            "auto-grow": "",
            "counter": "600",
            "filled": "",
            "row-height": "2",
            "rows": "2"
          },
          model: {
            value: _vm.developer_note,
            callback: function callback($$v) {
              _vm.developer_note = $$v;
            },
            expression: "developer_note"
          }
        })], 1), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "mr-3 text-transform-none",
          attrs: {
            "disabled": _vm.busy,
            "color": "primary",
            "depressed": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.expendRow(item);
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "mr-3 text-transform-none",
          attrs: {
            "loading": _vm.busy,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateNote(item);
            }
          }
        }, [_vm._v(" Update ")])], 1)], 1)], 1)], 1)];
      }
    }], null, false, 861035481)
  })], 1)], 1) : _c('div', [_c('no-data', {
    attrs: {
      "first-text": "No information"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "mx-2 mb-5 subListFont bold-text"
  }, [_vm._v("Attributes/Traits")]), _c('v-row', {
    staticClass: "mx-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" ID "), _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v("Identifier")])], 1), _c('div', [_vm._v(" " + _vm._s(((_vm$activeFeedback3 = _vm.activeFeedback) === null || _vm$activeFeedback3 === void 0 ? void 0 : (_vm$activeFeedback3$u = _vm$activeFeedback3.user) === null || _vm$activeFeedback3$u === void 0 ? void 0 : _vm$activeFeedback3$u.identity) || "-") + " ")])]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Name "), _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v("Reserved")])], 1), _c('div', [_vm._v(" " + _vm._s(((_vm$activeFeedback4 = _vm.activeFeedback) === null || _vm$activeFeedback4 === void 0 ? void 0 : (_vm$activeFeedback4$u = _vm$activeFeedback4.user) === null || _vm$activeFeedback4$u === void 0 ? void 0 : _vm$activeFeedback4$u.name) || "-") + " ")])]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Email "), _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v("Reserved")])], 1), _c('div', [_vm._v(" " + _vm._s(((_vm$activeFeedback5 = _vm.activeFeedback) === null || _vm$activeFeedback5 === void 0 ? void 0 : (_vm$activeFeedback5$u = _vm$activeFeedback5.user) === null || _vm$activeFeedback5$u === void 0 ? void 0 : _vm$activeFeedback5$u.email) || "-") + " ")])]), (_vm$activeFeedback6 = _vm.activeFeedback) !== null && _vm$activeFeedback6 !== void 0 && (_vm$activeFeedback6$u = _vm$activeFeedback6.user) !== null && _vm$activeFeedback6$u !== void 0 && _vm$activeFeedback6$u.traits ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1) : _vm._e(), (_vm$activeFeedback7 = _vm.activeFeedback) !== null && _vm$activeFeedback7 !== void 0 && (_vm$activeFeedback7$u = _vm$activeFeedback7.user) !== null && _vm$activeFeedback7$u !== void 0 && _vm$activeFeedback7$u.traits ? [_vm._l(Object.keys((_vm$activeFeedback8 = _vm.activeFeedback) === null || _vm$activeFeedback8 === void 0 ? void 0 : (_vm$activeFeedback8$u = _vm$activeFeedback8.user) === null || _vm$activeFeedback8$u === void 0 ? void 0 : _vm$activeFeedback8$u.traits), function (item, index) {
    var _vm$activeFeedback9, _vm$activeFeedback9$u, _vm$activeFeedback10, _vm$activeFeedback10$;
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "text-capitalize"
    }, [_vm._v(" " + _vm._s(item) + " ")]), _c('div', [_vm._v(" " + _vm._s(((_vm$activeFeedback9 = _vm.activeFeedback) === null || _vm$activeFeedback9 === void 0 ? void 0 : (_vm$activeFeedback9$u = _vm$activeFeedback9.user) === null || _vm$activeFeedback9$u === void 0 ? void 0 : _vm$activeFeedback9$u.traits[item]) || "-") + " ")])]), index + 1 < Object.keys((_vm$activeFeedback10 = _vm.activeFeedback) === null || _vm$activeFeedback10 === void 0 ? void 0 : (_vm$activeFeedback10$ = _vm$activeFeedback10.user) === null || _vm$activeFeedback10$ === void 0 ? void 0 : _vm$activeFeedback10$.traits).length ? _c('v-col', {
      key: index,
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider')], 1) : _vm._e()];
  })] : _vm._e()], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }