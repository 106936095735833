var render = function render(){
  var _vm$selectedEventDeta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [!_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('div', {
    staticClass: "watermark"
  }, [_vm._v(" Demo Data ")]) : _vm._e(), !_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('div', {
    staticClass: "secondwatermark"
  }, [_vm._v(" Demo Data ")]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [!_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('div', [_vm._v(" 2m:33s ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.getSessionDuration()) + " ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "subListFont"
  }, [_vm._v(" CPU "), !_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v(" Demo ")]) : _vm._e()], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('apexchart', {
    attrs: {
      "height": 100,
      "options": _vm.apexOptions,
      "series": _vm.cpuChartData,
      "type": "line"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "subListFont"
  }, [_vm._v(" RAM "), !_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v(" Demo ")]) : _vm._e()], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('apexchart', {
    attrs: {
      "height": 100,
      "options": _vm.ramChartOptions,
      "series": _vm.ramChartData,
      "type": "line"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "subListFont"
  }, [_vm._v(" Stats "), !_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v(" Demo ")]) : _vm._e()], 1)]), _c('v-col', {
    staticClass: "px-0 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('apexchart', {
    attrs: {
      "options": _vm.networkOptions,
      "series": _vm.networkSeries,
      "height": "100",
      "type": "rangeBar"
    }
  })], 1), _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('apexchart', {
    attrs: {
      "options": _vm.chartOptions,
      "series": _vm.batterySeries,
      "height": "100",
      "type": "rangeBar"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "subListFont"
  }, [_vm._v(" Events "), !_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v(" Demo ")]) : _vm._e()], 1), _c('apexchart', {
    attrs: {
      "options": _vm.eventOptions,
      "series": _vm.eventsChartData,
      "height": "75",
      "type": "scatter",
      "width": "100%"
    },
    on: {
      "markerClick": _vm.openDetailEventCard
    }
  }), _c('div', {
    staticClass: "subListFont"
  }, [_vm._v(" Errors "), !_vm.getTeamPlanUsage.feedback.sessions || !_vm.activeFeedback.session.id ? _c('v-chip', {
    attrs: {
      "label": "",
      "x-small": ""
    }
  }, [_vm._v(" Demo ")]) : _vm._e()], 1), _c('apexchart', {
    attrs: {
      "options": _vm.errorOptions,
      "series": _vm.errorChartData,
      "height": "75",
      "type": "scatter",
      "width": "100%"
    },
    on: {
      "markerClick": _vm.openDetailErrorCard
    }
  })], 1), (_vm$selectedEventDeta = _vm.selectedEventDetail) !== null && _vm$selectedEventDeta !== void 0 && _vm$selectedEventDeta.name ? [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "pb-0"
  }, [_vm._v(" " + _vm._s(_vm.selectedEventDetail.name) + " ")]), _c('div', {
    staticClass: "pb-2"
  }, [_c('v-chip', {
    attrs: {
      "color": _vm.selectedEventDetail.type === 'event' ? 'primary' : 'error',
      "label": "",
      "outlined": "",
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedEventDetail.type) + " ")])], 1), _vm._l(Object.keys(_vm.selectedEventDetail.parameters), function (parameter, pindex) {
    return _c('div', {
      key: pindex
    }, [_c('span', [_vm._v(_vm._s(parameter) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.selectedEventDetail.parameters[parameter] || "0") + " ")])]);
  })], 2)], 1)], 1)], 1)] : _vm._e()], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }