<template>
  <v-row>
    <v-col cols="12">
      <v-card :outlined="$vuetify.theme.dark" class="pa-2" flat>
        <div class="mb-5 mx-3 subListFont bold-text">
          Information
          <span class="float-right top">
            <action-dropdown
              v-if="activeFeedback?.id && activeFeedback.status !== 3"
              id="feedbackAction"
            >
              <template #list>
                <feedback-dropdown-item
                  :activeFeedback="activeFeedback"
                  :currentUser="currentUser"
                  @openDeleteDialog="
                    $emit('openDeleteDialog', activeFeedback.id)
                  "
                  @openFlagging="openFlagging"
                  @openUpdateFeedback="
                    $emit('openUpdateFeedback', activeFeedback)
                  "
                  @updateStatus="updateStatus"
                ></feedback-dropdown-item>
              </template>
            </action-dropdown>
          </span>
        </div>
        <v-row v-if="activeFeedback?.id" class="">
          <v-col class="py-0 pb-4" cols="12">
            <v-data-table
              :expanded="expanded"
              :headers="informationDetailHeader"
              :items="[activeFeedback]"
              class="v-card v-sheet v-sheet--outlined hide-v-data-footer__select"
              hide-default-footer
              single-expand
            >
              <template #item.country="{ item }">
                <div>
                  <country-city
                    :detail="item"
                    class="mt-2 ml-3 regular-font font-12 line-height-16"
                  ></country-city>
                </div>
              </template>
              <template #item.release="{ item }">
                <div v-if="item.release && item.release.status">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon
                          :color="
                            $options.RELEASE_STATUS[item.release.status].color
                          "
                          left
                          small
                          v-text="
                            item?.release?.platform === 'android'
                              ? 'mdi-android'
                              : 'mdi-apple'
                          "
                        ></v-icon>
                      </span>
                    </template>
                    <span>
                      <ul>
                        <li>
                          Status:
                          {{
                            $options.RELEASE_STATUS[item?.release?.status]
                              ?.title
                          }}
                        </li>
                        <li>
                          Version:
                          {{ item.release.version }}
                        </li>
                        <li>
                          Version Code:
                          {{ item.release.version_code }}
                        </li>
                        <li>
                          Size:
                          <span v-if="item?.release?.size >= 0">
                            {{ formatBytes(item.release.size) }}
                          </span>
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
                <div v-else>-</div>
              </template>
              <template #item.status="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-icon
                        :color="$options.STATUS[item?.status]?.color"
                        v-text="$options.STATUS[item?.status]?.icon"
                      ></v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $options.STATUS[item?.status]?.title }}
                  </span>
                </v-tooltip>
              </template>
              <template #item.note="{ item, expand, isExpanded }">
                <div>
                  <div v-if="activeFeedback.note">
                    <span v-if="canManageFeedback" class="float-right top">
                      <v-icon
                        @click="expendRow(item)"
                        v-text="'mdi-pencil'"
                      ></v-icon>
                    </span>
                    <vue-readmore
                      :length="300"
                      :text="activeFeedback.note || '-'"
                    ></vue-readmore>
                  </div>
                  <div v-else>
                    <span v-if="canManageFeedback">
                      <span
                        class="primary--text cursor-pointer"
                        @click="expendRow(item)"
                        >Click here</span
                      >
                      to update note
                    </span>
                    <span v-else> -</span>
                  </div>
                </div>
              </template>
              <template #expanded-item="{ headers, item, expand, isExpanded }">
                <td :colspan="headers.length" class="pa-0">
                  <v-card class="pa-4">
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="developer_note"
                          auto-grow
                          counter="600"
                          filled
                          row-height="2"
                          rows="2"
                        ></v-textarea>
                      </v-col>
                      <v-col class="text-right" cols="12">
                        <v-btn
                          :disabled="busy"
                          class="mr-3 text-transform-none"
                          color="primary"
                          depressed
                          text
                          @click="expendRow(item)"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :loading="busy"
                          :outlined="$vuetify.theme.dark"
                          class="mr-3 text-transform-none"
                          color="primary"
                          depressed
                          @click="updateNote(item)"
                        >
                          Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div v-else>
          <no-data first-text="No information"></no-data>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card :outlined="$vuetify.theme.dark" class="pa-2" flat>
        <div class="mx-2 mb-5 subListFont bold-text">Attributes/Traits</div>
        <v-row class="mx-2">
          <v-col cols="12">
            <div>
              ID
              <v-chip label x-small>Identifier</v-chip>
            </div>
            <div>
              {{ activeFeedback?.user?.identity || "-" }}
            </div>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <div>
              Name
              <v-chip label x-small>Reserved</v-chip>
            </div>
            <div>
              {{ activeFeedback?.user?.name || "-" }}
            </div>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <div>
              Email
              <v-chip label x-small>Reserved</v-chip>
            </div>
            <div>
              {{ activeFeedback?.user?.email || "-" }}
            </div>
          </v-col>
          <v-col v-if="activeFeedback?.user?.traits" class="py-0" cols="12">
            <v-divider></v-divider>
          </v-col>
          <template v-if="activeFeedback?.user?.traits">
            <template
              v-for="(item, index) in Object.keys(activeFeedback?.user?.traits)"
            >
              <v-col :key="index" cols="12">
                <div class="text-capitalize">
                  {{ item }}
                </div>
                <div>
                  {{ activeFeedback?.user?.traits[item] || "-" }}
                </div>
              </v-col>
              <v-col
                v-if="
                  index + 1 < Object.keys(activeFeedback?.user?.traits).length
                "
                :key="index"
                class="py-0"
                cols="12"
              >
                <v-divider></v-divider>
              </v-col>
            </template>
          </template>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatBytes } from "@/core/services/helper.service";
import FeedbackDropdownItem from "@/view/components/Common/FeedbackDropdownItem";
import { mapGetters } from "vuex";
import CountryCity from "@/view/components/Common/CountryCity";
import { UPDATE_FEEDBACK_INTERNAL_NOTES } from "@/store/releases/releases.module";
import VueReadmore from "@/view/components/Common/VueReadmore";

export default {
  props: {
    activeFeedback: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { VueReadmore, FeedbackDropdownItem, CountryCity },
  RELEASE_STATUS: {
    0: { title: "Deleted", color: "error" },
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "primary" },
  },
  STATUS: {
    1: {
      title: "Not Resolved",
      color: "orange",
      icon: "mdi-clock-outline",
    },
    3: {
      title: "Flagged for review ",
      color: "red",
      icon: "mdi-flag-outline",
    },
    2: {
      title: "Resolved",
      color: "green",
      icon: "mdi-check-circle-outline",
    },
  },
  data() {
    return {
      formatBytes,
      updateFeedbackNote: false,
      busy: false,
      expanded: [],
      developer_note: "",
      informationDetailHeader: [
        {
          text: "Country",
          align: "left",
          sortable: false,
          width: "6%",
          value: "country",
        },
        {
          text: "Release",
          align: "left",
          sortable: false,
          width: "6%",
          value: "release",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          width: "6%",
          value: "status",
        },
        {
          text: "Note",
          align: "left",
          sortable: false,
          width: "6%",
          value: "note",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
  },
  methods: {
    updateStatus(id, status) {
      this.$emit("updateStatus", id, status);
    },
    expendRow(item) {
      this.developer_note = item.note;
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
    updateNote(item) {
      this.updateNoteError = "";
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        feedback_id: this.activeFeedback.id,
        note: this.activeFeedback.note || this.developer_note,
      };
      this.$store
        .dispatch(UPDATE_FEEDBACK_INTERNAL_NOTES, payload)
        .then((response) => {
          this.busy = false;
          this.activeFeedback.note = this.developer_note;
          this.expendRow(item);
          (this.updateFeedbackNote = false),
            (this.selectedFeedback = {}),
            this.notifyUserMessage({ message: response.message });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    openFlagging(id, status) {
      this.$emit("openFlagging", id, status);
    },
  },
};
</script>
