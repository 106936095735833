var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "text-center darkGrey--text"
  }, [_vm._v(" Use TestApp.io SDK to collect valuable information. "), _c('br'), _c('a', {
    attrs: {
      "href": "https://help.testapp.io/about-sdk/",
      "target": "_blank"
    }
  }, [_vm._v(" Learn more... ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }