<template>
  <v-card>
    <v-card-title>
      <div class="subListFont">Feedback Detail</div>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-col class="pb-0" cols="12" md="9">
            <v-card class="pa-2" flat>
              <div class="pa-2 bold-text subListFont">Session</div>
              <v-data-table
                :expanded.sync="latestExpand"
                :headers="sessionHeaders"
                :items="feedbackSessions"
                mobile-breakpoint="0"
                class="v-card v-sheet v-sheet--outlined hide-v-data-footer__select radius-10 release-table"
                hide-default-footer
                @click:row="expendLatestRow"
              >
                <template #item.id="{ item }">
                  {{ item.id }}
                </template>
                <template #item.location="{ item }">
                  <country-city :detail="item.location"></country-city>
                </template>
                <template #item.stats="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> View </span>
                    </template>
                    <div>
                      <div>Errors: {{ item?.stats?.errors }}</div>
                      <div>Events: {{ item?.stats?.events }}</div>
                      <div>Screens: {{ item?.stats?.screens }}</div>
                      <div>Performance: {{ item?.stats?.performance }}</div>
                      <div>
                        User Attributes: {{ item?.stats?.user_attributes }}
                      </div>
                    </div>
                  </v-tooltip>
                </template>
                <template #item.time="{ item }">
                  <vue-hoverable-date
                    :date="dayjs(item.start_at).unix()"
                  ></vue-hoverable-date>
                </template>
                <template #item.action="{ item }">
                  <span
                    class="primary--text cursor-pointer"
                    @click.stop="
                      $router.push({
                        name: 'sessions-detail',
                        params: {
                          app_id: $route.params.app_id,
                          session_id: item.id,
                        },
                      })
                    "
                  >
                    Detail
                    <v-icon right v-text="'mdi-chevron-right'"></v-icon>
                  </span>
                </template>
                <template #expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-0">
                    <v-card class="pa-4" flat tile>
                      <v-row>
                        <v-col cols="12">
                          <span> Source: </span>
                          {{ item?.context?.sdk?.name }} SDK
                        </v-col>
                        <v-col class="py-0" cols="12">
                          <span> User agent: </span>
                          {{ item?.context?.user_agent }}
                        </v-col>
                        <v-col cols="12">
                          <span> IP: </span>
                          {{ item?.context?.ip }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-col>
        <v-col cols="12" md="12">
          <v-card class="pa-2 py-4" flat>
            <v-row align="center">
              <v-col class="lightBackground py-3 mx-2" cols="9" md="12">
                <span class="float-right top">
                  <action-dropdown
                    v-if="
                      activeFeedback?.id &&
                      activeFeedback?.status !== 3 &&
                      canManageApp
                    "
                    id="feedbackAction"
                  >
                    <template #list>
                      <v-list-item :disabled="busy">
                        <v-icon small>edit</v-icon>
                        <div
                          class="regular-font font-14"
                          @click="$emit('feedback-update-dialog')"
                        >
                          Edit Message
                        </div>
                      </v-list-item>
                    </template>
                  </action-dropdown>
                </span>
                <span>
                  <span v-if="!activeFeedback?.message"> No message </span>
                  <vue-readmore
                    v-else
                    :length="100"
                    :text="activeFeedback?.message"
                  ></vue-readmore>
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="12">
          <v-col v-if="attachmentLoading || loading" cols="4">
            <v-skeleton-loader type="card" />
          </v-col>
          <attachment-carosel
            v-if="!loading || !attachmentLoading"
            :activeFeedback="activeFeedback"
            :fileList="releaseInstallFeedback"
            :page="page"
            :selectedIndex="selectedIndex"
            @onConfirmDeleteAttachment="$emit('confirm-delete-attachment')"
            @update="$emit('update-attachment')"
          ></attachment-carosel>
        </v-col>
        <v-col cols="12" md="12">
          <div v-if="loading"></div>
          <information-notes
            v-else
            :activeFeedback="activeFeedback"
            @openDeleteDialog="$emit('delete-dialog', activeFeedback.id)"
            @openFlagging="openFlagging"
            @openUpdateFeedback="$emit('update-feedback', activeFeedback)"
            @updateStatus="updateStatus"
          ></information-notes>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { GET_RELEASE_INSTALL_FEEDBACK } from "@/store/releases/releases.module";
import AttachmentCarosel from "@/view/components/Feedback/Tabs/AttachmentCarosel";
import InformationNotes from "@/view/components/Feedback/Tabs/InformationNotes";
import VueReadmore from "@/view/components/Common/VueReadmore";
import { GET_SESSIONS_FEEDBACK } from "@/store/apps/sessions.module";

export default {
  props: {
    feedbackDetail: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    VueReadmore,
    AttachmentCarosel,
    InformationNotes,
  },
  data() {
    return {
      releaseInstallFeedback: [],
      activeFeedback: {},
      sessionHeaders: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Location",
          value: "location",
        },
        {
          text: "Stats",
          value: "stats",
        },
        {
          text: "Time",
          value: "time",
        },
        {
          text: "",
          value: "action",
        },
      ],
      feedbackSessions: [],
      loading: false,
      page: 1,
      busy: false,
      attachmentLoading: false,
      selectedIndex: 1,
      feedbackId: "",
      latestExpand: [],
    };
  },
  created() {
    if (this.feedbackDetail) {
      this.activeFeedback = this.feedbackDetail;
      this.feedbackId = this.feedbackDetail.id;
      this.selectedFeedback = this.feedbackDetail;
    } else {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_RELEASE_INSTALL_FEEDBACK, {
          app_id: this.$route?.params?.app_id || "",
          release_id: "",
          last_id: this.$route?.params?.feedback_id || "",
          shareId: "",
          external: false,
          single: true,
        })
        .then((data) => {
          this.releaseInstallFeedback = data.data;
          this.activeFeedback = data.data[0];
          this.feedbackId = data?.data[0]?.id;
          this.selectedFeedback = data.data[0];
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openFlagging(id, status) {
      this.$emit("open-flagging", id, status);
    },
    updateStatus(id, status) {
      this.$emit("update-status", id, status);
    },
    getFeedbackSessions(feedbackId) {
      this.$store
        .dispatch(GET_SESSIONS_FEEDBACK, {
          app_id: this.$route.params.app_id,
          feedback_id: this.$route.params.feedback_id || feedbackId,
          timestamp: "",
        })
        .then((response) => {
          this.feedbackSessions = response?.results[0]?.data;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
    expendLatestRow(item) {
      if (!item.value) {
        this.latestExpand = item === this.latestExpand[0] ? [] : [item];
      }
    },
  },
};
</script>
